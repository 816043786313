import BaseModel from './BaseModel';

/** Class representing an event. */
class Event extends BaseModel {
    /**
     * Prepare this object for update.
     * This is used to "normalize", if needed, some properties
     * before to send them.
     *
     * return{BaseModel}
     */
    prepareForUpdate() {
        const prepared = super.prepareForUpdate();
        Object.keys(prepared.title).map(t => {
            prepared.title[t] = prepared.title[t].trim();
        })

        if ('year' in prepared) {
            if (!prepared.year)
                prepared.year = null;
        }

        return prepared;
    }
}

export default Event;
