import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Button, Badge } from 'reactstrap';
import { Link } from "react-router-dom";
import PageTitle from '../../../components/PageTitle/PageTitle';
import TableButtons from '../../../components/TableButtons';
import CreateButton from '../../../components/CreateButton';
import { Filters } from '../../../services';
import _ from 'lodash';
import 'react-table-6/react-table.css'
import './TicketackUsersList.css';
import Table from '../../../components/Table/Table';
import { loadTktUsers, deleteTktUser } from '../../../redux/modules/tktusers';
import { loadSalepoints } from '../../../redux/modules/salepoints';
import i18n from '../../../locales/i18n';

const TicketackUsersList = () => {
    const { t }                                         = useTranslation();
    const dispatch                                      = useDispatch();
    const { tktusers }                                  = useSelector(state => state.tktusers);
    const { salepoints }                                = useSelector(state => state.salepoints);
    const [salepointsProcessed, setSalepointsProcessed] = useState();

    useEffect(() => {
        dispatch(loadTktUsers());
        dispatch(loadSalepoints());
    }, []);

    useEffect(() => {
        if (salepoints && salepoints.length > 0) {
            let temp = salepoints.map(salepoint => {
                return {
                    name: salepoint.name[i18n.language],
                    _id: salepoint._id
                }
            })
            setSalepointsProcessed(temp)
        }
    }, [salepoints])

    const prepareSalepoints = (salepoints) => {
        return (salepointsProcessed || [])
            .filter(s => salepoints?.includes(s._id))
            .map(s => s.name);
    };

    const prepareRolesAndSalespoints = (roles, color) => {
        return roles?.map((role, index) => {
            return <Badge key={index} style={{ margin: "3px" }} color={color} pill>{role}</Badge>
        }) || [];
    }

    let tableTktUsers = [];
    if (tktusers && tktusers.length > 0) {
        tableTktUsers = tktusers.map((tktuser, i) => {
            let rolesAndSalespoints = [];
            tktuser.roles.filter(r => rolesAndSalespoints.push(r));
            prepareSalepoints(tktuser.salepoints).filter(s => rolesAndSalespoints.push(s));
            return {
                name: tktuser.name,
                roles: [
                    <span key={i}>
                        {prepareRolesAndSalespoints(tktuser.roles, "warning")}<br />
                        {prepareRolesAndSalespoints(prepareSalepoints(tktuser.salepoints), "success")}
                    </span>],
                rolesAndSalespoints: rolesAndSalespoints,
                _id: tktuser._id
            };
        });
    };

    const actions =
        <div>
            {tktusers &&
                <CreateButton
                    module="ticketack_users"
                    text="tktUsers.add_a_ticketack_user"
                />
            }
        </div>

    const deleteTicketackUser = (id) => {
        if (window.confirm(t("tktUsers.delete_tktUser")))
            dispatch(deleteTktUser(id));
    }

    const roleAndSalepointsFilterMethod = (filter, user) => {
        return user._original.rolesAndSalespoints.some(f => f.toLowerCase().includes(filter.value.toLowerCase()));
    };

    let columns = [{
        Header: t('tktUsers.name'),
        accessor: "name",
        filterMethod: Filters.textFilterMethod("name"),
        Filter: Filters.textFilter,
        filterAll: true,
        maxWidth: 300,
    }, {
        Header: t('tktUsers.roles_and_salepoints'),
        accessor: "roles",
        filterMethod: roleAndSalepointsFilterMethod,
    }, {
        Header: t('common.actions'),
        accessor: "_id",
        maxWidth: 110,
        filterable: false,
        Cell: row => (
            <TableButtons
                id={row.value}
                actions={["show", "edit", "delete"]}
                module="ticketack_users"
            />
        ),
    }];

    return (
        <div className="TicketackUsersList">
            <PageTitle icon="users" title={t('tktUsers.tktUsers')} actions={actions} />
            <Table id="tktUsersTable" data={tableTktUsers} columns={columns} />
        </div>
    );
}

export default TicketackUsersList
