import React from 'react';
import { Utils } from '../../services';
import MultiSelectField from '../MultiSelectField/MultiSelectField';
import i18n from '../../locales/i18n';

import './TickettypesField.css';

const TickettypesField = ({ name, tickettypes }) => {
    const options = (tickettypes || []).map(tickettype => {
        return {
            key: tickettype._id,
            text: Utils.localized_or_fallback(tickettype.name, i18n.language),
            value: tickettype._id
        };
    });

    return <MultiSelectField name={ name } options={ options } />
}

export default TickettypesField;
