// Redux Pricinglists module
// see https://github.com/erikras/ducks-modular-redux

import { Pricinglists, Utils } from '../../services/';
import _ from 'lodash';

const LOADING_PRICINGLISTS               = 'kronos/pricinglists/LOADING_PRICINGLISTS';
const LOADING_PRICINGLISTS_SUCCESS       = 'kronos/pricinglists/LOADING_PRICINGLISTS_SUCCESS';
const LOADING_PRICINGLISTS_FAILURE       = 'kronos/pricinglists/LOADING_PRICINGLISTS_FAILURE';
const LOADING_PRICINGLIST                = 'kronos/pricinglists/LOADING_PRICINGLIST';
const LOADING_PRICINGLIST_SUCCESS        = 'kronos/pricinglists/LOADING_PRICINGLIST_SUCCESS';
const LOADING_PRICINGLIST_FAILURE        = 'kronos/pricinglists/LOADING_PRICINGLIST_FAILURE';
const UPDATING_PRICINGLIST               = 'kronos/pricinglists/UPDATING_PRICINGLIST';
const UPDATING_PRICINGLIST_SUCCESS       = 'kronos/pricinglists/UPDATING_PRICINGLIST_SUCCESS';
const UPDATING_PRICINGLIST_FAILURE       = 'kronos/pricinglists/UPDATING_PRICINGLIST_FAILURE';
const START_CREATING_PRICINGLIST         = 'kronos/pricinglists/START_CREATING_PRICINGLIST';
const CREATING_PRICINGLIST               = 'kronos/pricinglists/CREATING_PRICINGLIST';
const CREATING_PRICINGLIST_SUCCESS       = 'kronos/pricinglists/CREATING_PRICINGLIST_SUCCESS';
const CREATING_PRICINGLIST_FAILURE       = 'kronos/pricinglists/CREATING_PRICINGLIST_FAILURE';
const DELETING_PRICINGLIST               = 'kronos/pricinglists/DELETING_PRICINGLIST';
const DELETING_PRICINGLIST_SUCCESS       = 'kronos/pricinglists/DELETING_PRICINGLIST_SUCCESS';
const DELETING_PRICINGLIST_FAILURE       = 'kronos/pricinglists/DELETING_PRICINGLIST_FAILURE';
const ADDING_PRICING                     = 'kronos/pricinglists/ADDING_PRICING';
const REMOVING_PRICING                   = 'kronos/pricinglists/REMOVING_PRICING';
const DUPLICATING_PRICINGLIST            = 'kronos/pricinglists/DUPLICATING_PRICINGLIST';
const DUPLICATING_PRICINGLIST_SUCCESS    = 'kronos/pricinglists/DUPLICATING_PRICINGLIST_SUCCESS';
const DUPLICATING_PRICINGLIST_FAILURE    = 'kronos/pricinglists/DUPLICATING_PRICINGLIST_FAILURE';
const UPDATING_PRICINGLIST_ORDER         = 'kronos/pricinglists/UPDATING_PRICINGLIST_ORDER';
const UPDATING_PRICINGS_KEYS             = 'kronos/pricinglists/UPDATING_PRICINGS_KEYS';

const initialState = {
    isLoading: false,
    hasLoadingError: false,
    loadingErrorMessage: '',
    pricinglistSuccessfullyCreated: false,
    pricinglists: [],
    pricinglist: {},
    duplicatedPricinglist: {}
}

const initialStock = {
    salepoint_ids: [],
    availability: 0
};

const initialPricing = (names, currency, vat, eshopWeight) => {
    return {
        name: names,
        price: { [currency] : 0 },
        value: { [currency] : 0 },
        value_per_screening: { [currency] : 0 },
        wallet_amount: { [currency] : 0 },
        VAT: vat || 0,
        category: "default",
        sellers: ['eshop', 'on-site', 'admin'],
        opaque: {
            eshop_sort_weight: eshopWeight
        }
    }
}

// Reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOADING_PRICINGLISTS:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_PRICINGLISTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                pricinglistSuccessfullyDeleted: false,
                pricinglists: action.pricinglists
            };
        case LOADING_PRICINGLISTS_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case LOADING_PRICINGLIST:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_PRICINGLIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                pricinglist: action.pricinglist
            };
        case LOADING_PRICINGLIST_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case UPDATING_PRICINGLIST:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case UPDATING_PRICINGLIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                pricinglist: action.pricinglist
            };
        case UPDATING_PRICINGLIST_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case DUPLICATING_PRICINGLIST:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false,
            };
        case DUPLICATING_PRICINGLIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                duplicatedPricinglist: action.pricinglist
            };
        case DUPLICATING_PRICINGLIST_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case START_CREATING_PRICINGLIST:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                pricinglistSuccessfullyCreated: false,
                // Ensure sane default pricinglist for creation
                pricinglist: {
                    "name": "",
                    "pricings": {},
                }
            };
        case CREATING_PRICINGLIST:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case CREATING_PRICINGLIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                pricinglistSuccessfullyCreated: true,
                pricinglist: action.pricinglist
            };
        case CREATING_PRICINGLIST_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                pricinglistSuccessfullyCreated: false,
                loadingErrorMessage: action.error.message
            };
        case DELETING_PRICINGLIST:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case DELETING_PRICINGLIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                pricinglistSuccessfullyDeleted: true,
                pricinglist: action.pricinglist
            };
        case DELETING_PRICINGLIST_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                pricinglistSuccessfullyDeleted: false,
                loadingErrorMessage: action.error.message
            };
        case ADDING_PRICING:
            return {
                ...state,
                pricinglist: {
                    ...state.pricinglist,
                    pricings: {
                        ...state.pricinglist.pricings,
                        [Date.now()]: initialPricing(action.names, action.currency, action.vat, Object.keys(state.pricinglist.pricings).length + 1)
                    }
                }
            };
        case REMOVING_PRICING:
            const { [action._id]: toRemove, ...otherPricings } = state.pricinglist.pricings;
            return {
                ...state,
                pricinglist: {
                    ...state.pricinglist,
                    pricings: otherPricings
                }
            };
        case UPDATING_PRICINGLIST_ORDER:
            return {
                ...state,
                pricinglist: {
                    ...state.pricinglist,
                    pricings: action.pricings
                }
            };
        case UPDATING_PRICINGS_KEYS:
            let temp = _.cloneDeep(state.pricinglist.pricings);
            if (temp[action.values.newId]) {
                return {
                    ...state
                }
            }
            else {
                temp[action.values.newId] = temp[action.values.oldId]
                delete temp[action.values.oldId];
                return {
                    ...state,
                    pricinglist: {
                        ...state.pricinglist,
                        pricings: temp
                    }
                }
            }
        default:
            return state;
    }
};

// Actions
function loadingPricinglists() { return { type: LOADING_PRICINGLISTS } }
function loadingPricinglistsSuccess(pricinglists) { return { type: LOADING_PRICINGLISTS_SUCCESS, pricinglists: pricinglists } }
function loadingPricinglistsFailure(err) { return { type: LOADING_PRICINGLISTS_FAILURE, error: err } }
export function loadPricinglists(pricinglistname, password) {
    return (dispatch) => {
        dispatch(loadingPricinglists());
        Pricinglists.list()
            .then(data => {
                const pricinglists = data.pricinglists;
                dispatch(loadingPricinglistsSuccess(pricinglists));
            })
            .catch(err => {
                console.log("err => ", err);
                dispatch(loadingPricinglistsFailure(err))
            });
    }
}

function loadingPricinglist() { return { type: LOADING_PRICINGLIST } }
function loadingPricinglistSuccess(pricinglist) { return { type: LOADING_PRICINGLIST_SUCCESS, pricinglist: pricinglist } }
function loadingPricinglistFailure(err) { return { type: LOADING_PRICINGLIST_FAILURE, error: err } }
export function loadPricinglist(pricinglistId) {
    return (dispatch) => {
        dispatch(loadingPricinglist());
        Pricinglists.get(pricinglistId)
            .then(data => {
                const pricinglist = data.pricinglist;
                dispatch(loadingPricinglistSuccess(pricinglist));
            })
            .catch(err => {
                dispatch(loadingPricinglistFailure(err))
            });
    }
}

function updatingPricinglist() { return { type: UPDATING_PRICINGLIST } }
function updatingPricinglistSuccess(pricinglist) { return { type: UPDATING_PRICINGLIST_SUCCESS, pricinglist: pricinglist } }
function updatingPricinglistFailure(err) { return { type: UPDATING_PRICINGLIST_FAILURE, error: err } }
export function updatePricinglist(pricinglist) {
    return (dispatch) => {
        dispatch(updatingPricinglist());
        Pricinglists.update(pricinglist)
            .then(data => {
                const pricinglist = data.pricinglist;
                dispatch(updatingPricinglistSuccess(pricinglist));
            })
            .catch(err => {
                dispatch(updatingPricinglistFailure(err))
            });
    }
}

function creatingPricinglist() { return { type: CREATING_PRICINGLIST } }
function creatingPricinglistSuccess(pricinglist) { return { type: CREATING_PRICINGLIST_SUCCESS, pricinglist: pricinglist } }
function creatingPricinglistFailure(err) { return { type: CREATING_PRICINGLIST_FAILURE, error: err } }
export function startCreatePricinglist() { return { type: START_CREATING_PRICINGLIST } }
export function createPricinglist(pricinglist) {
    return (dispatch) => {
        dispatch(creatingPricinglist());
        Pricinglists.create(pricinglist)
            .then(data => {
                const pricinglist = data.pricinglist;
                dispatch(creatingPricinglistSuccess(pricinglist));
                dispatch(loadingPricinglistSuccess(pricinglist));
            })
            .catch(err => {
                dispatch(creatingPricinglistFailure(err))
            });
    }
}

function deletingPricinglist() { return { type: DELETING_PRICINGLIST } }
function deletingPricinglistSuccess() { return { type: DELETING_PRICINGLIST_SUCCESS } }
function deletingPricinglistFailure(err) { return { type: DELETING_PRICINGLIST_FAILURE, error: err } }
export function deletePricinglist(pricinglistId) {
    return (dispatch) => {
        dispatch(deletingPricinglist());
        Pricinglists.delete(pricinglistId)
            .then(data => {
                dispatch(deletingPricinglistSuccess());
            })
            .catch(err => {
                dispatch(deletingPricinglistFailure(err))
            });
    }
}

function duplicatingPricinglist() { return { type: DUPLICATING_PRICINGLIST } }
function duplicatingPricinglistSuccess(pricinglist) { return { type: DUPLICATING_PRICINGLIST_SUCCESS, pricinglist: pricinglist } }
function duplicatingPricinglistFailure(err) { return { type: DUPLICATING_PRICINGLIST_FAILURE, error: err } }
export function duplicatePricinglist(pricinglist) {
    return (dispatch) => {
        dispatch(duplicatingPricinglist());
        Pricinglists.duplicate(pricinglist)
            .then(data => {
                const pricinglist = data.pricinglist;
                dispatch(duplicatingPricinglistSuccess(pricinglist));
            })
            .catch(err => {
                dispatch(duplicatingPricinglistFailure(err))
            });
    }
}

function addingPricing(names, currency) {
    const vat = Utils.tax()?.vat_tickets ?? 0;
    return { type: ADDING_PRICING, names, currency, vat } }
export function addPricing(names, currency) {
    return (dispatch) => {
        dispatch(addingPricing(names, currency));
    }
}

function removingPricing(_id) { return { type: REMOVING_PRICING, _id } }
export function removePricing(_id) {
    return (dispatch) => {
        dispatch(removingPricing(_id));
    }
}

function updatingPricinglistePricings(pricings) { return { type: UPDATING_PRICINGLIST_ORDER, pricings } }
export function updatePricinglistPricings(pricings) {
    return (dispatch) => {
        dispatch(updatingPricinglistePricings(pricings));
    }
}

function updatingPricingsKeys(values) { return { type: UPDATING_PRICINGS_KEYS, values } }
export function updatePricingsKeys(values) {
    return (dispatch) => {
        dispatch(updatingPricingsKeys(values));
    }
}