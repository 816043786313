import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom'
import { Button, Nav, NavItem } from 'reactstrap';
import { Utils } from '../../../../services';
import { loadTktInstances } from '../../../../redux/modules/tktInstances';
import Config from '../../../../config/Config';
import TktSyncButton from '../../../../components/TktSyncButton/TktSyncButton';
import InstanceSelector from '../../../../components/InstanceSelector/InstanceSelector';
import { Ability } from '../../../../components';
import { updateUi } from '../../../../redux';

import './SideBar.css';

const SideBar = ({ section, onCollapseNavClick, collapsed, children }) => {
    const { t }        = useTranslation();
    const dispatch     = useDispatch();
    const { user }     = useSelector(state => state.auth);
    const { setting }  = useSelector(state => state.settings);

    const { isMobile, showChangelog } = useSelector(state => state.ui);

    useEffect(() => {
        if (user.abilities.superadmin)
            dispatch(loadTktInstances());
    }, [user]);

    const toggleChangelog = () => {
        dispatch(updateUi({showChangelog : !showChangelog}))
    }

    if (!user)
        return null;

    const tkt_instance = user.tkt_instance;

    return (
        <Nav className="SideBar">
            <div className={`sidebar-sticky d-flex flex-column  justify-content-between`}>
                <div className="w-100">
                    <div>
                        {children}
                    </div>
                    <div>
                        <InstanceSelector onCollapseNavClick={onCollapseNavClick} collapsed={(!isMobile && collapsed) ? collapsed : false} />
                    </div>
                    {tkt_instance.hasModule(Utils.MODULE_PLANNING) && user.can('editOther', 'programmations') &&
                        <TktSyncButton collapsed={(!isMobile && collapsed) ? collapsed : false} />
                    }
                    <div>
                        {section === Utils.SECTION_ADMIN &&
                            <div>
                                {tkt_instance.hasModule(Utils.MODULE_PLANNING) &&
                                    <div>
                                        <Ability
                                            anyModuleIn={['programmations', 'events', 'sections', 'places', 'distributors']}
                                        >
                                            <h6 className={`sidebar-heading d-flex ${collapsed ? "justify-content-center" : "justify-content-between"} px-3 mt-4 mb-1 title-category`}>
                                                <span className={`${collapsed ? "h5" : ""}`}>
                                                    <i className="fa fa-clock-o"></i>
                                                    <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.events")}</span>
                                                </span>
                                            </h6>
                                            <Nav className="flex-column" onClick={onCollapseNavClick}>
                                                {!tkt_instance.hasEventivalIntegration(setting) &&
                                                    <>
                                                        <Ability can='programmations:read'>
                                                            <NavItem>
                                                                <NavLink to="/programmations" className="nav-link">
                                                                    <i className="fa fa-calendar-plus-o"></i>
                                                                    <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.programmations")}</span>
                                                                </NavLink>
                                                            </NavItem>
                                                        </Ability>
                                                        <Ability can="events:read">
                                                            <NavItem>
                                                                <NavLink to="/events/movie" className="nav-link">
                                                                    <i className="fa fa-film"></i>
                                                                    <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.events_movies")}</span>
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem>
                                                                <NavLink to="/events/music_group" className="nav-link">
                                                                    <i className="fa fa-music"></i>
                                                                    <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.events_music_groups")}</span>
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem>
                                                                <NavLink to="/events/drama" className="nav-link">
                                                                    <i className="fa fa-puzzle-piece"></i>
                                                                    <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.events_drama")}</span>
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem>
                                                                <NavLink to="/events/service" className="nav-link">
                                                                    <i className="fa fa-gift"></i>
                                                                    <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.other_activites")}</span>
                                                                </NavLink>
                                                            </NavItem>
                                                        </Ability>
                                                        <Ability can="sections:read">
                                                            <NavItem>
                                                                <NavLink to="/sections" className="nav-link">
                                                                    <i className="fa fa-indent"></i>
                                                                    <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.sections")}</span>
                                                                </NavLink>
                                                            </NavItem>
                                                        </Ability>
                                                        <Ability can="distributors:read">
                                                            <NavItem>
                                                                <NavLink to="/distributors" className="nav-link">
                                                                    <i className="fa fa-money"></i>
                                                                    <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.distributors")}</span>
                                                                </NavLink>
                                                            </NavItem>
                                                        </Ability>
                                                    </>
                                                }
                                                <Ability can="places:read">
                                                    <NavItem>
                                                        <NavLink to="/places" className="nav-link">
                                                            <i className="fa fa-th"></i>
                                                            <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.places")}</span>
                                                        </NavLink>
                                                    </NavItem>
                                                </Ability>
                                            </Nav>
                                        </Ability>
                                    </div>
                                }
                                {tkt_instance.hasModule(Utils.MODULE_PUBLISHING) &&
                                    <div>
                                        <Ability anyModuleIn={['posts', 'post_categories', 'channels']}>
                                            <>
                                                <h6 className={`sidebar-heading d-flex ${collapsed ? "justify-content-center" : "justify-content-between"} px-3 mt-4 mb-1 title-category`}>
                                                    <span className={`${collapsed ? "h5" : ""}`}>
                                                        <i className="fa fa-commenting-o" aria-hidden="true"></i>
                                                        <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.publishing")}</span>
                                                    </span>
                                                </h6>
                                            </>
                                            <Nav className="flex-column" onClick={onCollapseNavClick}>
                                                <>
                                                    <Ability can="posts:read">
                                                        <NavItem>
                                                            <NavLink to="/posts" className="nav-link">
                                                                <i className="fa fa-newspaper-o"></i>
                                                                <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.posts")}</span>
                                                            </NavLink>
                                                        </NavItem>
                                                    </Ability>
                                                    <Ability can="post_categories:read">
                                                        <NavItem>
                                                            <NavLink to="/post_categories" className="nav-link">
                                                                <i className="fa fa-folder-o"></i>
                                                                <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.categories")}</span>
                                                            </NavLink>
                                                        </NavItem>
                                                    </Ability>
                                                    <Ability can="channels:read">
                                                        <NavItem>
                                                            <NavLink to="/channels" className="nav-link">
                                                                <i className="fa fa-road"></i>
                                                                <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.channels")}</span>
                                                            </NavLink>
                                                        </NavItem>
                                                    </Ability>
                                                </>
                                            </Nav>
                                        </Ability>
                                    </div>
                                }
                                <div>
                                    <Ability anyModuleIn={['articles', 'articlescategories', 'tickettypes', 'pricinglists', 'layouts']}>
                                        {(tkt_instance.hasModule(Utils.MODULE_SHOP) || tkt_instance.hasModule(Utils.MODULE_PLANNING)) &&
                                            <>
                                                <h6 className={`sidebar-heading d-flex ${collapsed ? "justify-content-center" : "justify-content-between"} px-3 mt-4 mb-1 title-category`}>
                                                    <span className={`${collapsed ? "h5" : ""}`}>
                                                        <i className="fa fa-shopping-basket"></i>
                                                        <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.sale")}</span>
                                                    </span>
                                                </h6>
                                            </>
                                        }
                                        <Nav className="flex-column" onClick={onCollapseNavClick}>
                                            {tkt_instance.hasModule(Utils.MODULE_SHOP) &&
                                                <>
                                                    <Ability can="articles:read">
                                                        <NavItem>
                                                            <NavLink to="/articles" className="nav-link">
                                                                <i className="fa fa-list-ul"></i>
                                                                <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.articles_and_discount")}</span>
                                                            </NavLink>
                                                        </NavItem>
                                                    </Ability>
                                                    <Ability can="articlecategories:read">
                                                        <NavItem>
                                                            <NavLink to="/articlecategories" className="nav-link">
                                                                <i className="fa fa-folder-o"></i>
                                                                <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.categories")}</span>
                                                            </NavLink>
                                                        </NavItem>
                                                    </Ability>
                                                </>
                                            }
                                            {tkt_instance.hasModule(Utils.MODULE_PLANNING) &&
                                                <>
                                                    <Ability can="tickettypes:read">
                                                        <NavItem>
                                                            <NavLink to="/tickettypes" className="nav-link">
                                                                <i className="fa fa-tags"></i>
                                                                <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.tickettypes")}</span>
                                                            </NavLink>
                                                        </NavItem>
                                                    </Ability>
                                                    <Ability can="pricinglists:read">
                                                        <NavItem>
                                                            <NavLink to="/pricinglists" className="nav-link">
                                                                <i className="fa fa-usd"></i>
                                                                <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.pricinglists")}</span>
                                                            </NavLink>
                                                        </NavItem>
                                                    </Ability>
                                                    <Ability can="layouts:edit">
                                                        <NavItem>
                                                            <NavLink to="/layouts" className="nav-link">
                                                                <i className="fa fa-file-pdf-o"></i>
                                                                <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.layouts")}</span>
                                                            </NavLink>
                                                        </NavItem>
                                                    </Ability>
                                                    <Ability can="layouts:edit">
                                                        <NavItem>
                                                            <NavLink to="/email_layouts" className="nav-link">
                                                                <i className="fa fa-envelope-o"></i>
                                                                <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.email_layouts")}</span>
                                                            </NavLink>
                                                        </NavItem>
                                                    </Ability>
                                                </>
                                            }
                                        </Nav>
                                    </Ability>
                                </div>
                                <div>
                                    <Ability anyModuleIn={['salepoints', 'cashregisters', 'phases', 'sectors', 'application_types', 'form_layouts']}>
                                        <h6 className={`sidebar-heading d-flex ${collapsed ? "justify-content-center" : "justify-content-between"} px-3 mt-4 mb-1 title-category`}>
                                            <span className={`${collapsed ? "h5" : ""}`}>
                                                <i className="fa fa-bolt"></i>
                                                <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.company")}</span>
                                            </span>
                                        </h6>
                                        <Nav className="flex-column" onClick={onCollapseNavClick}>
                                            {(tkt_instance.hasModule(Utils.MODULE_SHOP) || tkt_instance.hasModule(Utils.MODULE_PLANNING)) &&
                                                <>
                                                    <Ability can="salepoints:read">
                                                        <NavItem>
                                                            <NavLink to="/salepoints" className="nav-link">
                                                                <i className="fa fa-map-marker"></i>
                                                                <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.points_of_sale")}</span>
                                                            </NavLink>
                                                        </NavItem>
                                                    </Ability>
                                                    <Ability can="cashregisters:read">
                                                        <NavItem>
                                                            <NavLink to="/cashregisters" className="nav-link">
                                                                <i className="fa fa-th"></i>
                                                                <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.cashregisters")}</span>
                                                            </NavLink>
                                                        </NavItem>
                                                    </Ability>
                                                </>
                                            }
                                            {(tkt_instance.hasModule(Utils.MODULE_TEAM)) &&
                                                <>
                                                    <Ability can="phases:read">
                                                        <NavItem>
                                                            <NavLink to="/phases" className="nav-link">
                                                                <i className='fa fa-arrows-h'></i>
                                                                <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.phases")}</span>
                                                            </NavLink>
                                                        </NavItem>
                                                    </Ability>
                                                    <Ability can="sectors:read">
                                                        <NavItem>
                                                            <NavLink to="/sectors" className="nav-link">
                                                                <i className='fa fa-location-arrow'></i>
                                                                <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t('app.sectors')}</span>
                                                            </NavLink>
                                                        </NavItem>
                                                    </Ability>
                                                    <Ability can="application_types:read">
                                                        <NavItem>
                                                            <NavLink to="/application_types" className="nav-link">
                                                                <i className="fa fa-address-book"></i>
                                                                <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.application_types")}</span>
                                                            </NavLink>
                                                        </NavItem>
                                                    </Ability>
                                                    <Ability can="form_layouts:read">
                                                        <NavItem>
                                                            <NavLink to="/form_layouts" className="nav-link">
                                                                <i className='fa fa-check-square-o'></i>
                                                                <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t('app.form_layouts')}</span>
                                                            </NavLink>
                                                        </NavItem>
                                                    </Ability>
                                                </>
                                            }
                                        </Nav>
                                    </Ability>
                                </div>
                                <div>
                                    <Ability anyModuleIn={['users', 'ticketack_users', 'resources', 'activities']}>
                                        <h6 className={`sidebar-heading d-flex ${collapsed ? "justify-content-center" : "justify-content-between"} px-3 mt-4 mb-1 title-category`}>
                                            <span className={`${collapsed ? "h5" : ""}`}>
                                                <i className="fa fa-users"></i>
                                                <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.teams")}</span>
                                            </span>
                                        </h6>
                                        <Nav className="flex-column" onClick={onCollapseNavClick}>
                                           <Ability can="users:read">
                                                <NavItem>
                                                    <NavLink to="/users" className="nav-link">
                                                        <i className="fa fa-cogs"></i>
                                                        <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.users")}</span>
                                                    </NavLink>
                                                </NavItem>
                                            </Ability>
                                            <Ability can="ticketack_users:read">
                                                <NavItem>
                                                    <NavLink to="/ticketack_users" className="nav-link">
                                                        <i className="fa fa-users"></i>
                                                        <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.ticketack_users")}</span>
                                                    </NavLink>
                                                </NavItem>
                                            </Ability>
                                            <Ability can="resources:read">
                                                <NavItem>
                                                    <NavLink to="/resources" className="nav-link">
                                                        <i className="fa fa-key"></i>
                                                        <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.resources_keys")}</span>
                                                    </NavLink>
                                                </NavItem>
                                            </Ability>
                                            {(tkt_instance.hasModule(Utils.MODULE_RESOURCES) || tkt_instance.hasModule(Utils.MODULE_TEAM)) &&
                                                <Ability can="activities:read">
                                                    <NavItem>
                                                        <NavLink to="/activities" className="nav-link">
                                                            <i className="fa fa-id-badge"></i>
                                                            <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.activities")}</span>
                                                        </NavLink>
                                                    </NavItem>
                                                </Ability>
                                            }
                                        </Nav>
                                    </Ability>
                                </div>
                                <div>
                                    <Ability anyModuleIn={['settings', 'hardware', 'configuration_qr_codes']}>
                                        <h6 className={`sidebar-heading d-flex ${collapsed ? "justify-content-center" : "justify-content-between"} px-3 mt-4 mb-1 title-category`}>
                                            <span className={`${collapsed ? "h5" : ""}`}>
                                                <i className="fa fa-wrench"></i>
                                                <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.setting_and_hardware")}</span>
                                            </span>
                                        </h6>
                                        <Nav className="flex-column" onClick={onCollapseNavClick}>
                                            <Ability can="settings:any">
                                                <NavItem>
                                                    <NavLink to="/settings/edit/default" className="nav-link">
                                                        <i className="fa fa-gear"></i>
                                                        <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.settings")}</span>
                                                    </NavLink>
                                                </NavItem>
                                            </Ability>
                                            <Ability can="settings:any">
                                                <NavItem>
                                                    <NavLink to="/devices" className="nav-link">
                                                        <i className="fa fa-cubes"></i>
                                                        <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.hardware")}</span>
                                                    </NavLink>
                                                </NavItem>
                                            </Ability>
                                            <Ability can="settings:any">
                                                <NavItem>
                                                    <NavLink to="configuration_qr_codes" className="nav-link btn btn-secondary">
                                                        <i className="fa fa-qrcode"></i>
                                                        <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;
                                                            {t("app.configuration_qr_codes")}
                                                        </span>
                                                    </NavLink>
                                                </NavItem>
                                            </Ability>
                                        </Nav>
                                    </Ability>
                                </div>
                            </div>
                        }
                        {section === Utils.SECTION_OPERATION &&
                            <>
                            <Ability anyModuleIn={['customers', 'messages', 'programmations']}>
                                <Nav className="flex-column" onClick={onCollapseNavClick}>
                                    <h6 className={`sidebar-heading d-flex ${collapsed ? "justify-content-center" : "justify-content-between"} px-3 mt-4 mb-1 title-category`}>
                                        <span className={`${collapsed ? "h5" : ""}`}>
                                            <i className="fa fa-user"></i>
                                            <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.tickets_clients")}</span>
                                        </span>
                                    </h6>
                                    {setting?.eshop?.registration?.enabled &&
                                        <Ability can="customers:read">
                                            <NavItem>
                                                <NavLink to="/customers" className="nav-link">
                                                    <i className="fa fa-users"></i>
                                                    <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.customers")}</span>
                                                </NavLink>
                                            </NavItem>
                                        </Ability>
                                    }
                                    {tkt_instance.hasModule(Utils.MODULE_PLANNING) &&
                                        <Ability can="screenings:read">
                                            <NavItem>
                                                <NavLink to="/screenings" className="nav-link">
                                                    <i className="fa fa-television"></i>
                                                    <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.screenings")}</span>
                                                </NavLink>
                                            </NavItem>
                                        </Ability>
                                    }
                                </Nav>
                            </Ability>
                            <Ability anyModuleIn={['customers', 'messages', 'tasks', 'programmations', 'applications']}>
                                <Nav className="flex-column" onClick={onCollapseNavClick}>
                                    <h6 className={`sidebar-heading d-flex ${collapsed ? "justify-content-center" : "justify-content-between"} px-3 mt-4 mb-1 title-category`}>
                                        <span className={`${collapsed ? "h5" : ""}`}>
                                            <i className="fa fa-users"></i>
                                            <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.teams")}</span>
                                        </span>
                                    </h6>
                                </Nav>
                                {tkt_instance.hasModule(Utils.MODULE_RESOURCES) &&
                                    <Ability can="tasks:read">
                                        <NavItem>
                                            <NavLink to="/tasks" className="nav-link">
                                                <i className="fa fa-calendar"></i>
                                                <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.planning")}</span>
                                            </NavLink>
                                        </NavItem>
                                    </Ability>
                                }
                                {(tkt_instance.hasModule(Utils.MODULE_TEAM)) &&
                                    <Ability can="plan:read">
                                        <NavItem>
                                            <NavLink to="/plan" className="nav-link">
                                                <i className="fa fa-calendar-plus-o "></i>
                                                <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.plan")}</span>
                                            </NavLink>
                                        </NavItem>
                                    </Ability>
                                }
                            </Ability>
                            {(tkt_instance.hasModule(Utils.MODULE_TEAM)) &&
                                <Ability can="applications:read">
                                    <NavItem>
                                        <NavLink to="/applications" className="nav-link">
                                            <i className="fa fa-address-book-o "></i>
                                            <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.applications")}</span>
                                        </NavLink>
                                    </NavItem>
                                </Ability>
                            }
                            {tkt_instance.hasModule(Utils.MODULE_COMMUNICATIONS) &&
                                <Ability can="messages:create">
                                    <NavItem>
                                        <NavLink to="/messages" className="nav-link">
                                            <i className="fa fa-envelope"></i>
                                            <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.communication")}</span>
                                        </NavLink>
                                    </NavItem>
                                </Ability>
                            }
                            </>
                        }
                        {section === Utils.SECTION_ANALYSIS &&
                            <Nav className="flex-column" onClick={onCollapseNavClick}>
                                <Ability can="stats:read">
                                    <h6 className={`sidebar-heading d-flex ${collapsed ? "justify-content-center" : "justify-content-between"} px-3 mt-4 mb-1 title-category`}>
                                        <span className={`${collapsed ? "h5" : ""}`}>
                                            <i className="fa fa-shopping-basket"></i>
                                            <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("app.stats")}</span>
                                        </span>
                                    </h6>
                                    <NavItem>
                                        <NavLink to="/stats/movies" className="nav-link">
                                            <i className="fa fa-film"></i>
                                            <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("statstypes.movies")}</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink to="/stats/prices" className="nav-link">
                                            <i className="fa fa-tag"></i>
                                            <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("statstypes.prices")}</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink to="/stats/places" className="nav-link">
                                            <i className="fa fa-map-marker"></i>
                                            <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("statstypes.halls")}</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink to="/stats/days" className="nav-link">
                                            <i className="fa fa-calendar"></i>
                                            <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("statstypes.income_daily")}</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink to="/stats/hours" className="nav-link">
                                            <i className="fa fa-clock-o"></i>
                                            <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("statstypes.income_hourly")}</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink to="/stats/box_office" className="nav-link">
                                            <i className="fa fa-star"></i>
                                            <span className={`${collapsed ? "collapsedSpan" : "expandedSpan"}`}>&nbsp;{t("statstypes.box_office")}</span>
                                        </NavLink>
                                    </NavItem>
                                </Ability>
                            </Nav>
                        }
                    </div>
                </div>
                {!collapsed && (
                    <Button size="sm" color="info" className="w-100 text-center rounded-0" onClick={toggleChangelog}>
                        Version { Config.app_version }&nbsp;<i className='fa fa-info-circle'></i>
                    </Button>
                )}
            </div>
        </Nav>
    );
}

export default SideBar;
