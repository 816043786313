import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Link } from "react-router-dom";
import PageTitle from '../../../components/PageTitle/PageTitle';
import { withTranslation } from 'react-i18next';
import { Utils } from '../../../services/';

import { loadEvent } from '../../../redux/modules/events';

class EventShow extends Component<{}> {
    componentDidMount() {
        const { eventId, type } = this.props.match.params;
        // Load events on component mount
        this.props.dispatchLoadEvent(type, eventId);
    }

    render() {
        const { event }    = this.props.events;
        const { t }        = this.props;
        const default_lang = Utils.default_lang();

        let title = <span>{t("events.event_plural")}</span>;
        if (event)
            title = (
                <span>
                    {t("events.event")}
                    <small> | { event.name } </small>
                </span>
            );

        const actions =
        <div>
            <ButtonGroup>
                <Button tag={Link} to="/events" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t("common.back")}
                </Button>
                {event &&
                    <Button tag={Link} to={ `/events/edit/${ event.id }` } color="info" size="sm">
                        <i className="fa fa-edit"></i>&nbsp;{t("common.edit")}
                    </Button>
                }
            </ButtonGroup>
        </div>

        return (
            <div className="EventShow">
                <PageTitle icon="th" title={ title } actions={ actions } />
                {event &&
                    <div>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle><i className="fa fa-event"></i>&nbsp;{t("events.informations")}</CardTitle>
                                        <CardText tag="div">
                                            <div><b>{t("events.name")}</b>: { event.name }</div>
                                            <div><b>{t("events.description")}</b>: { event.description[default_lang] }</div>
                                            <div><b>{t("events.capacity")}</b>: { event.capacity }</div>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadEvent: (eventType, eventId) => loadEvent(eventType, eventId),
}

const mapStateToProps = state => ({
    events: state.events
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translations")(EventShow))
