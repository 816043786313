import BaseModel from './BaseModel';
import { User, FormLayout } from '../models';

/** Class representing a sector. */
class ApplicationType extends BaseModel {

    constructor(properties) {
        super(properties);

        if (this.form)
            this.form = new FormLayout(this.form);

        if (this.admins) {
            this.admins = (this.admins || []).map(props => new User(props));
            this.admin_ids = this.admin_ids || this.admins.map(user => user.id);
        }
    }
}

export default ApplicationType;
