import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Input } from 'reactstrap';
import PageTitle from '../../../components/PageTitle/PageTitle';
import matchSorter from 'match-sorter';
import { loadPlaces } from '../../../redux/modules/places';
import 'react-table-6/react-table.css'
import Table from '../../../components/Table/Table';
import TableButtons from '../../../components/TableButtons';
import CreateButton from '../../../components/CreateButton';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import './PlaceList.css';


const PlacesList = () => {
    const { places }              = useSelector(state => state.places);
    const dispatch                = useDispatch();
    const { t }                   = useTranslation();
    const [data, setData]         = useState([]);
    const { user }                = useSelector(state => state.auth);
    const { setting }             = useSelector(state => state.settings);
    const hasEventivalIntegration = setting && user?.tkt_instance?.hasEventivalIntegration(setting);

    useEffect(() => {
        dispatch(loadPlaces());
    }, []);

    useEffect(() => {
        if (places && places.length > 0) {
            let temp = places.map(p => {
                return {
                    name: p.name,
                    number: p.number ? parseInt(p.number) : "",
                    cinema: p.cinema,
                    capacity: parseInt(p.opaque.capacity),
                    _id: p._id
                }
            })
            setData(temp);
        }
    }, [places]);

    const textFilter = ({ filter, onChange }) =>
        <Input
            type="text"
            onChange={event => onChange(event.target.value)}
        />;
    const textFilterMethod = (key) => (filter, rows) => matchSorter(rows, filter.value, { keys: [key] });

    const tableActions = ["show", "edit"];
    if (!hasEventivalIntegration)
        tableActions.push('delete');

    const columns = [{
        Header: t("places.building"),
        accessor: "cinema",
        filterMethod: textFilterMethod("cinema"),
        Filter: textFilter,
        filterAll: true
    }, {
        Header: t("places.number"),
        accessor: "number",
        filterMethod: textFilterMethod("number"),
        Filter: textFilter,
        filterAll: true
    }, {
        Header: t("places.name"),
        accessor: "name",
        filterMethod: textFilterMethod("name"),
        Filter: textFilter,
        filterAll: true
    }, {
        Header: t("places.capacity"),
        accessor: "capacity",
        filterable: false,
    }, {
        Header: t("common.actions"),
        accessor: "_id",
        maxWidth: 110,
        filterable: false,
        Cell: row => (
            <TableButtons
                id={row.value}
                actions={tableActions}
                module="places"
            />
        )
    }];

    const actions =
        <CreateButton
            module={`places`}
            text="places.add"
        />

    const title =
        <span>{t("places.place_plural")}</span>

    return (
        <div className="PlacesList">
            <PageTitle icon="th" title={title} actions={hasEventivalIntegration ? "" : actions} />
            {hasEventivalIntegration &&
                <div className="alertMessage alert alert-danger"> {t("places.alert_message_list")}</div>
            }
            <Table
                id="placesTable"
                data={data}
                columns={columns}
                columnsSortOrder={["cinema", "number", "name"]}
            />
        </div>
    );
}

export default PlacesList;
