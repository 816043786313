import React, { useEffect, useState, useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Form, FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Utils, hasModule } from '../../../services/';
import {
    TranslatableField,
    DaysField,
    HoursField,
    DateTimePicker,
    WysiwygField,
    SectionsField,
    EventsField
} from '../../../components';
import ProgrammationEvents from './ProgrammationEvents/ProgrammationEvents';
import ProgrammationTasks from './ProgrammationTasks/ProgrammationTasks';
import _ from 'lodash';
import moment from 'moment'
import 'moment/locale/fr';
import momentLocalizer from 'react-widgets-moment';
import { getProgrammationsLinkedResources } from '../../../redux';
import 'react-widgets/dist/css/react-widgets.css'

import "./Programmations.css";

moment.locale('fr');
momentLocalizer();

let ProgrammationForm = ({ handleSubmit, pristine, submitting, isCreation, events_ids }) => {
    const { t }                                          = useTranslation();
    const dispatch                                       = useDispatch();
    const { isLoading, programmation, linkedResources }  = useSelector(state => state.programmations);
    const events                                         = linkedResources.events ?? [];
    const activities                                     = linkedResources.activities ?? [];
    const users                                          = linkedResources.users ?? [];
    const places                                         = linkedResources.places ?? [];
    const sections                                       = linkedResources.sections ?? [];
    const pricinglists                                   = linkedResources.pricinglists ?? [];
    const [programmationState, setProgrammationState]    = useState({
        period_nb: 1,
        period_type: Utils.PERIOD_WEEK,
        start_at: moment().startOf('day'),
        stop_at: moment().endOf('day').add(1, 'week'),
        events_ids: events_ids ?? null,
        booking_mode: programmation?.options?.booking_mode
    });

    useEffect(() => {
        dispatch(getProgrammationsLinkedResources());
        if (events_ids) {
            setState(events_ids, events_ids.split(','));
        }
    }, [])

    const setState = (key, value) => {
        let clonedState = _.cloneDeep(programmationState);
        clonedState[key] = value;
        setProgrammationState(clonedState);
    }

    const goBack = () => {
        window.history.back();
    }

    const onChangePeriodNb = (e) => {
        setState("period_nb", e.target.value, () => { updateStopAt(); });
    }

    const onChangePeriodType = (e) => {
        setState("period_type", e.target.value, () => { updateStopAt(); });
    }

    const onChangeStartAt = (date) => {
        setState("start_at", moment(date), () => { updateStopAt(); });
    }

    const onChangeBookingMode = (s) => {
        setState("booking_mode", s.target.value ? s.target.value : null);
    }

    const updateStopAt = () => {
        let stop_at = programmationState.start_at.clone().endOf('day');
        const period_nb = programmationState.period_nb;
        switch (programmationState.period_type) {
            case Utils.PERIOD_DAY:
                setState("stop_at", stop_at.add(period_nb - 1, 'days'));
                break;
            case Utils.PERIOD_WEEK:
            case Utils.PERIOD_CINEMA_WEEK:
                setState("stop_at", stop_at.add(7 * period_nb - 1, 'days'));
                break;
            case Utils.PERIOD_MONTH:
                setState("stop_at", stop_at.endOf('month'));
                break;
            case Utils.PERIOD_YEAR:
                setState("stop_at", stop_at.endOf('year'));
                break;
        }
    }

    const processedPlaces = useMemo(() => {
        const result = {};
        if (places.length > 0) {
            places.forEach(p => {
                if (!result[p.cinema])
                    result[p.cinema] = [];
                result[p.cinema].push({
                    name: p.name,
                    _id: p._id
                });
            })
        }
        return result;
    }, [places]);

    if (!programmation || !linkedResources)
        return null;

    const period_type = programmationState.period_type;
    let datetimePickerViewMode = 'days';
    if (period_type == Utils.PERIOD_MONTH)
        datetimePickerViewMode = 'months';
    else if (period_type == Utils.PERIOD_YEAR)
        datetimePickerViewMode = 'years';

    const isValidDate = (current) => {
        switch (programmationState.period_type) {
            case Utils.PERIOD_DAY:
            case Utils.PERIOD_WEEK:
                return true;
            case Utils.PERIOD_CINEMA_WEEK:
                return current.day() == 3;
            case Utils.PERIOD_MONTH:
                datetimePickerViewMode = 'months';
                return true;
            case Utils.PERIOD_YEAR:
                datetimePickerViewMode = 'years';
                return true;
        };
    };

    return (
        <Form className="ProgrammationForm" onSubmit={handleSubmit}>
            <Row>
                <Col sm={12} lg={6} xl={9}>
                    {programmationState.events_ids && programmationState.events_ids.length &&
                        <input type="hidden" name="new_event" value={programmationState.events_ids[0]} />
                    }
                    {!programmationState.events_ids &&
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle><i className="fa fa-info-cubes"></i>&nbsp;{t("programmations.events")}</CardTitle>
                                        <CardText tag="div">
                                            {events &&
                                                <FormGroup row>
                                                    <Label for="new_event" sm={3} xl={12}>{`${t("programmations.add_event")}`}</Label>
                                                    <Col>
                                                        <EventsField name="new_event" events={events} />
                                                        {!isCreation &&
                                                            <Button type="submit" color="info" size="sm" disabled={pristine || submitting || isLoading}>
                                                                {isLoading ?
                                                                    <span> &nbsp;<i className="fa fa-spinner fa-spin"></i> </span>
                                                                    :
                                                                    <span> <i className="fa fa-plus"></i></span>
                                                                }
                                                                &nbsp; {t("common.add")}
                                                            </Button>
                                                        }
                                                    </Col>
                                                </FormGroup>
                                            }
                                            <FieldArray name="events" component={ProgrammationEvents} events={programmation.events} />
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                    {programmation && programmation.events.length > 0 &&
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle><i className="fa fa-info-cubes"></i>&nbsp;{t("programmations.title")}</CardTitle>
                                        <CardText tag="div">
                                            {events &&
                                                <FormGroup row>
                                                    <Label for="title" sm={3}>{t("programmations.title")}</Label>
                                                    <Col sm={9}>
                                                        <TranslatableField name="title" placeholder={t("programmations.title_placeholder")} component="input" />
                                                    </Col>
                                                </FormGroup>
                                            }
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="fa fa-comments-o"></i>&nbsp;{t("programmations.description")}</CardTitle>
                                    <CardText tag="div">
                                        <FormGroup row>
                                            <Col>
                                                <TranslatableField name="description" component={WysiwygField} />
                                            </Col>
                                        </FormGroup>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="fa fa-calendar-plus-o"></i>&nbsp;{t("programmations.timing")}</CardTitle>
                                    <CardText tag="div">
                                        <Row>
                                            <Col sm={12} md={12} xl={6}>
                                                <FormGroup row>
                                                    <Label for="days" xl={12}>
                                                        {`${t("programmations.days")}`}
                                                        &nbsp;/&nbsp;
                                                        {`${t("programmations.hours")}`}
                                                    </Label>
                                                    <Col>
                                                        <Field name="days" component={DaysField} days={programmation.days} />
                                                        <Field name="times" component={HoursField} />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={12} md={12} xl={6}>
                                                <FormGroup row>
                                                    <Label for="period" sm={3} xl={12}>{`${t("programmations.period")}`}</Label>
                                                    <Col>
                                                        <Row>
                                                            <Col xs={12} sm={4}>
                                                                <Field component="input" className="form-control" type="text" name="period_nb" onChange={onChangePeriodNb} />
                                                            </Col>
                                                            <Col>
                                                                <Field component="select" className="form-control" onChange={onChangePeriodType} type="text" name="period_type">
                                                                    {Utils.periods().map((p) => (
                                                                        <option key={p} value={p}>
                                                                            {t('common.' + p)}
                                                                        </option>
                                                                    ))}
                                                                </Field>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </FormGroup>
                                                <Row>
                                                    <Col>
                                                        <FormGroup row>
                                                            <Label for="start_at" sm={3} xl={12}>{`${t("programmations.start_at")}`}</Label>
                                                            <Col>
                                                                <Field
                                                                    component={DateTimePicker}
                                                                    isValidDate={isValidDate}
                                                                    viewMode={datetimePickerViewMode}
                                                                    type="text"
                                                                    name="start_at"
                                                                    timeFormat={false}
                                                                    onChange={onChangeStartAt}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup row>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {hasModule(Utils.MODULE_RESOURCES) && !isCreation && activities && activities.length > 0 && users &&
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle><i className="fa fa-id-badge"></i>&nbsp;{t("programmations.tasks")}</CardTitle>
                                        <CardText tag="div">
                                            <FormGroup row>
                                                <Col>
                                                    <FieldArray name="tasks" component={ProgrammationTasks} tasks={programmation.tasks} activities={activities} users={users} />
                                                </Col>
                                            </FormGroup>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                </Col>
                <Col md={12} lg={6} xl={3}>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="fa fa-th"></i>&nbsp;{t("places.place")}</CardTitle>
                                    <CardText tag="div">
                                        <FormGroup row>
                                            <Col>
                                                <FormGroup row>
                                                    <Label for="place_id" sm={3} xl={12}>{`${t("places.place")}`}</Label>
                                                    <Col sm={9}>
                                                        <Field required component="select" className="form-control" type="text" name="place_id" id="place_id">
                                                            <option key="0" value="">---</option>
                                                            {
                                                                Object.keys(processedPlaces).sort().map((cinema) => 
                                                                    <optgroup label={cinema}>
                                                                        {processedPlaces[cinema].sort(Utils.sortOnProperty('name')).map(p =>
                                                                            <option key={p._id} value={p._id}>{p.name}</option>
                                                                        )}
                                                                    </optgroup>
                                                                )
                                                            }
                                                        </Field>
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </FormGroup>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="fa fa-ticket"></i>&nbsp;{t("programmations.booking_option")}</CardTitle>
                                    <CardText tag="div">
                                        <FormGroup row>
                                            <Label for="booking_mode" sm={3} xl={12}>{t("programmations.booking_mode.title")}</Label>
                                            <Col>
                                                <Field component="select" className="form-control" type="text" name="options.booking_mode" onChange={onChangeBookingMode}>
                                                    <option key="" value="">{`${t("programmations.booking_mode.normal")}`}</option>
                                                    {
                                                        Utils.bookingModes().map((mode) => <option key={mode} value={mode}>{t(`programmations.booking_mode.${mode}`)}</option>)
                                                    }
                                                </Field>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            {pricinglists && !programmationState.booking_mode && (
                                                <Col sm={9}>
                                                    <Field component="select" className="form-control" type="text" name="pricinglist_id" id="pricinglist_id">
                                                        <option key="0" value="">{`${t("pricinglists.pricinglist_choice")}`}</option>
                                                        {
                                                            pricinglists
                                                                .sort(Utils.sortOnProperty('name'))
                                                                .map((p) =>
                                                                    <option key={p._id} value={p._id}>{p.name}</option>
                                                                )
                                                        }
                                                    </Field>
                                                </Col>
                                            )}
                                            {(programmationState.booking_mode === Utils.BOOKING_MODE_FREE) && (
                                                <Col sm={12}>
                                                    <TranslatableField
                                                        name="options.booking_note"
                                                        id="booking_note"
                                                        autoComplete="booking_note"
                                                        component={WysiwygField}
                                                        optional
                                                        placeholder={t('programmations.booking_mode.free_placeholder')}
                                                    />
                                                </Col>
                                            )}
                                            {(programmationState.booking_mode === Utils.BOOKING_MODE_EMAIL) && (
                                                <Col sm={12}>
                                                    <TranslatableField
                                                        name="options.booking_note"
                                                        id="booking_note"
                                                        autoComplete="booking_note"
                                                        component="input"
                                                        placeholder={t('programmations.booking_mode.email_placeholder')}
                                                    />
                                                </Col>
                                            )}
                                            {(programmationState.booking_mode === Utils.BOOKING_MODE_OTHER) && (
                                                <Col sm={12}>
                                                    <TranslatableField
                                                        name="options.booking_note"
                                                        id="booking_note"
                                                        autoComplete="booking_note"
                                                        component={WysiwygField}
                                                        optional
                                                        placeholder={t('programmations.booking_mode.other_placeholder')}
                                                    />
                                                </Col>
                                            )}
                                        </FormGroup>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="fa fa-film"></i>&nbsp;{t("programmations.details")}</CardTitle>
                                    <CardText tag="div">
                                        <FormGroup row>
                                            <Col>
                                                <FormGroup row>
                                                    <Label for="week" sm={3} xl={12}>{t("programmations.week")}</Label>
                                                    <Col sm={9}>
                                                        <Field required component="input" className="form-control" type="number" name="options.week" id="week" autoComplete="week" />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="section" sm={3} xl={12}>{t("programmations.section")}</Label>
                                                    <Col sm={9}>
                                                        <SectionsField name="options.sections" className="form-control" sections={sections} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="_3d" sm={3} xl={12}>{t("programmations._3d")}</Label>
                                                    <Col sm={9}>
                                                        <Field component="select" className="form-control" type="text" name="options._3d" normalize={Utils.normalizeBoolean}>
                                                            <option key="false" value={false}>{t("common.no")}</option>
                                                            <option key="true" value={true}>{t("common.yes")}</option>
                                                        </Field>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="break" sm={3} xl={12}>{t("programmations.break")}</Label>
                                                    <Col sm={9}>
                                                        <Field component="select" className="form-control" type="text" name="options.break" normalize={Utils.normalizeBoolean}>
                                                            <option key="false" value={false}>{t("common.no")}</option>
                                                            <option key="true" value={true}>{t("common.yes")}</option>
                                                        </Field>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="version" sm={3} xl={12}>{t("programmations.version")}</Label>
                                                    <Col sm={9}>
                                                        <Field component="input" className="form-control" type="text" name="options.version" id="version" autoComplete="version" />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="format" sm={3} xl={12}>{t("programmations.format")}</Label>
                                                    <Col sm={9}>
                                                        <Field component="input" className="form-control" type="text" name="options.format" id="format" autoComplete="format" />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="support" sm={3} xl={12}>{t("programmations.support")}</Label>
                                                    <Col sm={9}>
                                                    <Field component="select" className="form-control" type="text" name="options.support">
                                                        <option key="0" value="">---</option>
                                                        {
                                                            Utils.programmation_supports().map((s) => <option key={s} value={s}>{t("programmations.support_" + s)}</option>)
                                                        }
                                                    </Field>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="break" sm={3} xl={12}>{t("programmations.ignore_on_maccsbox")}</Label>
                                                    <Col sm={9}>
                                                        <Field component="select" className="form-control" type="text" name="options.ignore_on_maccsbox" normalize={Utils.normalizeBoolean}>
                                                            <option key="false" value={false}>{t("common.no")}</option>
                                                            <option key="true" value={true}>{t("common.yes")}</option>
                                                        </Field>
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </FormGroup>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col className="text-center">
                    <ButtonGroup>
                        <Button onClick={goBack} color="warning">
                            <span><i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}</span>
                        </Button>
                        <Button type="submit" color="success" disabled={submitting || isLoading}>
                            <span>
                                <i className={`fa ${isLoading ? 'fa-spinner fa-spin' : 'fa fa-save'}`}></i>&nbsp;{t("common.save")}
                            </span>
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
        </Form>
    );
}

const mapDispatchToProps = {}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
ProgrammationForm = reduxForm({
    form: 'programmationForm', // a unique identifier for this form
    enableReinitialize: true,
    destroyOnUnmount: false
})(ProgrammationForm)

// You have to connect() to any reducers that you wish to connect to yourself
ProgrammationForm = connect(
    state => ({
        // pull initial values from programmations reducer
        initialValues: {
            place_id: state.places && state.places.places && state.places.places.length == 1 ? state.places.places[0]._id : null,
            ...state.programmations.programmation,
        },
        programmations: state.programmations
    }), mapDispatchToProps
)(ProgrammationForm)

export default ProgrammationForm;
