// Redux Programmations module
// see https://github.com/erikras/ducks-modular-redux

import { Programmations } from '../../services/';
import { Utils } from '../../services/';
import moment from 'moment'
import { createRestSlices, applyReducers } from './rest';

const { initialState, linkedResourcesReducer, linkedResourcesAction } = createRestSlices(Programmations);

const LOADING_PROGRAMMATIONS         = 'kronos/programmations/LOADING_PROGRAMMATIONS';
const LOADING_PROGRAMMATIONS_SUCCESS = 'kronos/programmations/LOADING_PROGRAMMATIONS_SUCCESS';
const LOADING_PROGRAMMATIONS_FAILURE = 'kronos/programmations/LOADING_PROGRAMMATIONS_FAILURE';
const LOADING_PROGRAMMATION          = 'kronos/programmations/LOADING_PROGRAMMATION';
const LOADING_PROGRAMMATION_SUCCESS  = 'kronos/programmations/LOADING_PROGRAMMATION_SUCCESS';
const LOADING_PROGRAMMATION_FAILURE  = 'kronos/programmations/LOADING_PROGRAMMATION_FAILURE';
const UPDATING_PROGRAMMATION         = 'kronos/programmations/UPDATING_PROGRAMMATION';
const UPDATING_PROGRAMMATION_SUCCESS = 'kronos/programmations/UPDATING_PROGRAMMATION_SUCCESS';
const UPDATING_PROGRAMMATION_FAILURE = 'kronos/programmations/UPDATING_PROGRAMMATION_FAILURE';
const START_CREATING_PROGRAMMATION   = 'kronos/programmations/START_CREATING_PROGRAMMATION';
const CREATING_PROGRAMMATION         = 'kronos/programmations/CREATING_PROGRAMMATION';
const CREATING_PROGRAMMATION_SUCCESS = 'kronos/programmations/CREATING_PROGRAMMATION_SUCCESS';
const CREATING_PROGRAMMATION_FAILURE = 'kronos/programmations/CREATING_PROGRAMMATION_FAILURE';
const DELETING_PROGRAMMATION         = 'kronos/programmations/DELETING_PROGRAMMATION';
const DELETING_PROGRAMMATION_SUCCESS = 'kronos/programmations/DELETING_PROGRAMMATION_SUCCESS';
const DELETING_PROGRAMMATION_FAILURE = 'kronos/programmations/DELETING_PROGRAMMATION_FAILURE';
const GENERATING_SCREENINGS          = 'kronos/programmations/GENERATING_SCREENINGS';
const GENERATING_SCREENINGS_SUCCESS  = 'kronos/programmations/GENERATING_SCREENINGS_SUCCESS';
const GENERATING_SCREENINGS_FAILURE  = 'kronos/programmations/GENERATING_SCREENINGS_FAILURE';


initialState.isGenerating                     = false;
initialState.progBeingGenerated               = null;
initialState.hasGeneratingError               = false;
initialState.generatingErrorMessage           = '';
initialState.programmationSuccessfullyCreated = false;
initialState.screeningsSuccessfullyGenerated  = false;
initialState.programmations                   = [];
initialState.imported                         = [];
initialState.linkedResources                  = {};

const defaultDescription = Utils.default_translatable_fields();

// Reducer
const reducer = (state = initialState, action) => {
    switch(action.type) {
        case LOADING_PROGRAMMATIONS:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false,
                programmation: null,
                programmationSuccessfullyDeleted: false
            };
        case LOADING_PROGRAMMATIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                programmations: action.programmations
            };
        case LOADING_PROGRAMMATIONS_FAILURE:
            return {
                ...state,
                programmations: [],
                isLoading: false,
                hasLoadingError: true,
                programmationSuccessfullyCreated: false,
                loadingErrorMessage: action.error.message
            };
        case LOADING_PROGRAMMATION:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_PROGRAMMATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                programmation: action.programmation
            };
        case LOADING_PROGRAMMATION_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case UPDATING_PROGRAMMATION:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case UPDATING_PROGRAMMATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                programmation: action.programmation
            };
        case UPDATING_PROGRAMMATION_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case START_CREATING_PROGRAMMATION:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                programmationSuccessfullyDeleted: false,
                programmationSuccessfullyCreated: false,
                programmation: {
                    days: [],
                    times: [],
                    period_nb: 1,
                    period_type: Utils.PERIOD_WEEK,
                    events: [],
                    tasks: [],
                    start_at: moment().startOf('day'),
                    stop_at: moment().endOf('day').add(1, 'week'),
                    description: defaultDescription,
                    options: {
                        "week": 1,
                        "section": null,
                        "_3d": false,
                        "break": false,
                        "version": "",
                        "format": "",
                        "ignore_on_maccsbox": false
                    }
                }
            };
        case CREATING_PROGRAMMATION:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false,
                programmationSuccessfullyDeleted: false
            };
        case CREATING_PROGRAMMATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                programmationSuccessfullyCreated: true,
                programmation: action.programmation
            };
        case CREATING_PROGRAMMATION_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                programmationSuccessfullyCreated: false,
                loadingErrorMessage: action.error.message
            };
        case DELETING_PROGRAMMATION:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false,
                programmationSuccessfullyDeleted: false
            };
        case DELETING_PROGRAMMATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                programmationSuccessfullyDeleted: true,
                programmation: action.programmation
            };
        case DELETING_PROGRAMMATION_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                programmationSuccessfullyDeleted: false,
                loadingErrorMessage: action.error.message
            };
        case GENERATING_SCREENINGS:
            return {
                ...state,
                isGenerating: true,
                progBeingGenerated: action.programmation_id,
                screeningsSuccessfullyGenerated: false,
                hasGeneratingError: false
            };
        case GENERATING_SCREENINGS_SUCCESS:
            return {
                ...state,
                isGenerating: false,
                progBeingGenerated: null,
                hasGeneratingError: false,
                screeningsSuccessfullyGenerated: true,
                imported: action.imported
            };
        case GENERATING_SCREENINGS_FAILURE:
            return {
                ...state,
                isGenerating: false,
                progBeingGenerated: null,
                hasGeneratingError: true,
                screeningsSuccessfullyGenerated: false,
                generatingErrorMessage: action.error.message
            };
        default:
            return state;
    }
};

/* Export reducer */
export default (state = initialState, action) => {
    return applyReducers(state, action, [ linkedResourcesReducer, reducer ]);
}

// Actions
function loadingProgrammations() { return { type: LOADING_PROGRAMMATIONS } }
function loadingProgrammationsSuccess(programmations) { return { type: LOADING_PROGRAMMATIONS_SUCCESS, programmations: programmations } }
function loadingProgrammationsFailure(err) { return { type: LOADING_PROGRAMMATIONS_FAILURE, error: err } }
export function loadProgrammations(events_ids) {
    return (dispatch) => {
        dispatch(loadingProgrammations());
        Programmations.list(events_ids)
            .then(data => {
                const programmations = data.programmations;
                dispatch(loadingProgrammationsSuccess(programmations));
            })
            .catch(err => {
                console.log("err => ", err);
                dispatch(loadingProgrammationsFailure(err))
            });
    }
}

function loadingProgrammation() { return { type: LOADING_PROGRAMMATION } }
function loadingProgrammationSuccess(programmation) { return { type: LOADING_PROGRAMMATION_SUCCESS, programmation: programmation } }
function loadingProgrammationFailure(err) { return { type: LOADING_PROGRAMMATION_FAILURE, error: err } }
export function loadProgrammation(programmationId) {
    return (dispatch) => {
        dispatch(loadingProgrammation());
        Programmations.get(programmationId)
            .then(data => {
                const programmation = data.programmation;
                dispatch(loadingProgrammationSuccess(programmation));
            })
            .catch(err => {
                dispatch(loadingProgrammationFailure(err))
            });
    }
}

function updatingProgrammation() { return { type: UPDATING_PROGRAMMATION } }
function updatingProgrammationSuccess(programmation) { return { type: UPDATING_PROGRAMMATION_SUCCESS, programmation: programmation } }
function updatingProgrammationFailure(err) { return { type: UPDATING_PROGRAMMATION_FAILURE, error: err } }
export function updateProgrammation(programmation) {
    return (dispatch) => {
        dispatch(updatingProgrammation());
        Programmations.update(programmation)
            .then(data => {
                const programmation = data.programmation;
                dispatch(updatingProgrammationSuccess(programmation));
            })
            .catch(err => {
                dispatch(updatingProgrammationFailure(err))
            });
    }
}

function creatingProgrammation() { return { type: CREATING_PROGRAMMATION } }
function creatingProgrammationSuccess(programmation) { return { type: CREATING_PROGRAMMATION_SUCCESS, programmation: programmation } }
function creatingProgrammationFailure(err) { return { type: CREATING_PROGRAMMATION_FAILURE, error: err } }
export function startCreateProgrammation() { return { type: START_CREATING_PROGRAMMATION }};
export function createProgrammation(programmation) {
    return (dispatch) => {
        dispatch(creatingProgrammation());
        Programmations.create(programmation)
            .then(data => {
                const programmation = data.programmation;
                dispatch(creatingProgrammationSuccess(programmation));
                dispatch(loadingProgrammationSuccess(programmation));
            })
            .catch(err => {
                dispatch(creatingProgrammationFailure(err))
            });
    }
}

function deletingProgrammation() { return { type: DELETING_PROGRAMMATION } }
function deletingProgrammationSuccess() { return { type: DELETING_PROGRAMMATION_SUCCESS } }
function deletingProgrammationFailure(err) { return { type: DELETING_PROGRAMMATION_FAILURE, error: err } }
export function deleteProgrammation(programmationId) {
    return (dispatch) => {
        dispatch(deletingProgrammation());
        Programmations.delete(programmationId)
            .then(data => {
                dispatch(deletingProgrammationSuccess());
            })
            .catch(err => {
                dispatch(deletingProgrammationFailure(err))
            });
    }
}

function generatingScreenings(programmation_id) { return { type: GENERATING_SCREENINGS, programmation_id: programmation_id } }
function generatingScreeningsSuccess(programmation) { return { type: GENERATING_SCREENINGS_SUCCESS, programmation: programmation } }
function generatingScreeningsFailure(err) { return { type: GENERATING_SCREENINGS_FAILURE, error: err } }
export function generateScreenings(programmation_id) {
    return (dispatch) => {
        dispatch(generatingScreenings(programmation_id));
        Programmations.generateScreenings(programmation_id)
            .then(data => {
                const programmation = data.programmation;
                dispatch(generatingScreeningsSuccess(programmation));
            })
            .catch(err => {
                console.log("err => ", err);
                dispatch(generatingScreeningsFailure(err))
            });
    }
}

export const getProgrammationsLinkedResources = linkedResourcesAction;
