import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Button } from 'reactstrap';
import { Loader, PageTitle, Planner, SectorSelector } from '../../components';
import { loadSectors, loadShifts, loadPhases } from '../../redux';
import { Utils } from '../../services';
import moment from 'moment';

import './Plan.css';

const now = moment().hours(8).toDate();

const Plan = (props) => {
    const dispatch    = useDispatch();
    const { t, i18n } = useTranslation();

    const [startDate, setStartDate]        = useState(now);
    const [selectedPhase, selectPhase]     = useState(null);
    const [selectedSectorId, selectSector] = useState(null);

    const { shifts }  = useSelector(state => state.shifts);
    const { phases }  = useSelector(state => state.phases);
    const { sectors } = useSelector(state => state.sectors);

    useEffect(() => {
        dispatch(loadPhases());
        dispatch(loadSectors());
        dispatch(loadShifts());
    }, []);

    useEffect(() => {
        if (phases?.length) {
            phases.sort((a, b) => a.start_at - b.start_at);
            selectPhase(phases[0]);
        }
    }, [phases]);

    useEffect(() => {
        if (selectedPhase)
            setStartDate(selectedPhase.start_at.toDate());
    }, [ selectedPhase ]);

    const title = <span>{t("plan.title")}</span>;

    const actions = null

    const resources = React.useMemo(() => sectors?.sort((a, b) => {
        return Utils.localized_or_fallback(a.name, i18n.language).localeCompare(
            Utils.localized_or_fallback(b.name, i18n.language)
        );
    }).filter(sector => {
        if (!selectedSectorId)
            return true;

        return selectedSectorId === sector.id;
    }).map(sector => ({
        id: sector.id,
        title: Utils.localized_or_fallback(sector.name, i18n.language)
    })), [ sectors, selectedSectorId, i18n.language ]);

    const tasks = shifts?.map(shift => ({
        id: shift.id,
        start: shift.start_at.toDate(),
        end: shift.stop_at.toDate(),
        resourceId: shift.sector_id,
        title: `${shift.nb_volunteers} / ${shift.nb_volunteers}`,
        shift,
    }));

    return (
        <div className="Plan">
            <PageTitle icon="calendar-plus-o" title={title} actions={actions} />
            <div className="my-2 text-center">
                {phases && (
                    <ButtonGroup className="w-100">
                    { phases.map(phase => (
                        <Button
                            key={ phase.id }
                            color={ phase.id === selectedPhase?.id ? 'primary' : 'dark'}
                            onClick={ () => selectPhase(phase) }
                        >
                            <h3>
                                { Utils.localized_or_fallback(phase.name, i18n.language) }
                            </h3>
                            <div>{ t('plan.from') } { phase.start_at.format('LL') }</div>
                            <div>{ t('plan.to') } { phase.stop_at.format('LL') }</div>
                        </Button>
                    ))}
                    </ButtonGroup>
                )}

                <hr />

                { sectors && (
                <SectorSelector
                    sectors={ sectors }
                    placeholder={ t('plan.select_sector') }
                    onChange={ (e, data) => selectSector(data.value) }
                    single
                />
                )}

            </div>
            <Planner
                sectors={ sectors }
                resources={ resources }
                tasks={ tasks }
                currentDate={ startDate }
            />
        </div>
    );
}

export default Plan;
