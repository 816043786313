import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { Form, FormGroup, Label } from 'reactstrap';
import { useTranslation, withTranslation } from 'react-i18next';
import { loadEvents } from '../../../redux/modules/events';
import { loadPlaces } from '../../../redux/modules/places';
import { Utils } from '../../../services';
import { FileUpload, DateTimePicker, TranslatableField, WysiwygField } from '../../../components';
import ScreeningEvents from './ScreeningEvents/ScreeningEvents';
import ScreeningPosters from './ScreeningPosters/ScreeningPosters';
import TasksList from '../../Tasks/TasksList/TasksList';
import classnames from 'classnames';
import moment from 'moment'
import 'moment/locale/fr';
import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css'
import _ from 'lodash';
import { getProgrammationsLinkedResources } from '../../../redux';

import './OldScreeningForm.css';

moment.locale('fr');
momentLocalizer();

let OldScreeningForm = ({ handleSubmit, pristine, submitting, isCreation, needsSynchronization, handleTktSync }) => {
    const dispatch                       = useDispatch();
    const [activeTab, setActiveTab]      = useState('1')
    const default_lang                   = Utils.default_lang();
    const { t }                          = useTranslation();
    const { isLoading, legacyScreening } = useSelector(state => state.legacyScreenings);
    const events                         = useSelector(state => state.programmations.linkedResources.events);
    const places                         = useSelector(state => state.programmations.linkedResources.places);
    const { user }                       = useSelector(state => state.auth);
    const {
        imported,
        isTktsyncing,
        hasTktsyncingError,
        tktsyncingErrorMessage
    } = useSelector(state => state.legacyScreenings);

    const tktInstance = user?.tkt_instance;

    const goBack = () => {
        window.history.back();
    }

    useEffect(() => {
        dispatch(getProgrammationsLinkedResources(['places', 'events']));
    }, [])

    const toggle = (tab) => {
        if (activeTab !== tab)
            setActiveTab(tab);
    }

    let events_groups = [];
    if (events && events.length) {
        events_groups = _.uniq(events.map((e) => e.event_type)).map((t) => {
            return {
                label: Utils.event_types(t).label,
                events: _.sortBy(events.filter((e) => e.event_type === t), `title.${default_lang}`)
            };
        });
    }

    return (
        <Form onSubmit={handleSubmit} className="OldScreeningForm">
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => { toggle('1'); }}
                    >
                        <i className="fa fa-info-circle"></i>&nbsp;
                        {t("screenings.informations")}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => { toggle('2'); }}
                    >
                        <i className="fa fa-camera"></i>&nbsp;
                        {t("screenings.posters")} ({legacyScreening.posters && legacyScreening.posters.length || 0})
                    </NavLink>
                </NavItem>
                {tktInstance.hasModule(Utils.MODULE_RESOURCES) && !isCreation &&
                    <>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '3' })}
                                onClick={() => { toggle('3'); }}
                            >
                                <i className="fa fa-id-badge"></i>&nbsp;
                                {t("screenings.tasks")}
                            </NavLink>
                        </NavItem>
                    </>
                }
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <Row>
                        <Col lg={6} xl={9}>
                            <Row>
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <CardTitle><i className="fa fa-info-circle"></i>&nbsp;{t("screenings.informations")}</CardTitle>
                                            <CardText tag="div">
                                                <FormGroup row>
                                                    <Label for="title" sm={3}>{t("screenings.title")}</Label>
                                                    <Col sm={9}>
                                                        <TranslatableField name="title" placeholder="" component="input" />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="start_at" sm={3}>{t("screenings.start_at")}</Label>
                                                    <Col sm={9}>
                                                        <Field required component={DateTimePicker} type="text" name="start_at" />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="week" sm={3}>{t("screenings.week")}</Label>
                                                    <Col sm={9}>
                                                        <Field component="input" className="form-control" type="text" name="week" id="week" autoComplete="week" />
                                                    </Col>
                                                </FormGroup>
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            {!isCreation &&
                                <Row>
                                    <Col sm={12}>
                                        <Card>
                                            <CardBody>
                                                <CardTitle><i className="fa fa-info-cubes"></i>&nbsp;{t("screenings.events")}</CardTitle>
                                                <CardText tag="div">
                                                    {events &&
                                                        <FormGroup row>
                                                            <Label for="new_event" sm={3} xl={12}>{`${t("screenings.add_event")}`}</Label>
                                                            <Col>
                                                                <Field component="select" className="form-control" type="text" name="new_event">
                                                                    <option key="0" value="">---</option>
                                                                    {events_groups.map((g) => (
                                                                        <optgroup key={g.label} label={g.label}>{
                                                                            g.events.map((e) => <option key={e.id} value={e.id}>{e.title ? Utils.ucfirst(Utils.localized_or_fallback(e.title, default_lang)) : '---'}</option>)
                                                                        }
                                                                        </optgroup>
                                                                    ))}
                                                                </Field>
                                                                <Button type="submit" color="info" size="sm" disabled={pristine || submitting || isLoading}>
                                                                    {isLoading ?
                                                                        <span> &nbsp;<i className="fa fa-spinner fa-spin"></i> </span>
                                                                        :
                                                                        <span> <i className="fa fa-plus"></i></span>
                                                                    }
                                                                    &nbsp; {t("common.add")}
                                                                </Button>
                                                            </Col>
                                                        </FormGroup>
                                                    }
                                                    {legacyScreening.events.length > 0 &&
                                                        <FieldArray name="events" component={ScreeningEvents} events={legacyScreening.events} />
                                                    }
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            }
                            <Row>
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <CardTitle><i className="fa fa-comments-o"></i>&nbsp;{t("screenings.description")}</CardTitle>
                                            <CardText tag="div">
                                                <FormGroup row>
                                                    <Col>
                                                        <TranslatableField name="description" component={WysiwygField} />
                                                    </Col>
                                                </FormGroup>
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12} lg={6} xl={3}>
                            <Row>
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <CardTitle><i className="fa fa-th"></i>&nbsp;{t("places.place")}</CardTitle>
                                            <CardText tag="div">
                                                <FormGroup row>
                                                    <Col>
                                                        <FormGroup row>
                                                            <Label for="place_id" sm={3} xl={12}>{`${t("places.place")}`}</Label>
                                                            <Col sm={9}>
                                                                <Field required component="select" className="form-control" type="text" name="place_id" id="place_id">
                                                                    <option key="0" value="">---</option>
                                                                    {
                                                                        places?.map((p) => <option key={p._id} value={p._id}>{p.name}</option>)
                                                                    }
                                                                </Field>
                                                            </Col>
                                                        </FormGroup>
                                                    </Col>
                                                </FormGroup>
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <CardTitle><i className="fa fa-film"></i>&nbsp;{t("screenings.details")}</CardTitle>
                                            <CardText tag="div">
                                                <FormGroup row>
                                                    <Col>
                                                        <FormGroup row>
                                                            <Label for="_3d" sm={3} xl={12}>{t("screenings._3d")}</Label>
                                                            <Col sm={9}>
                                                                <Field component="select" className="form-control" type="text" name="_3d" normalize={Utils.normalizeBoolean}>
                                                                    <option key="false" value={false}>{t("common.no")}</option>
                                                                    <option key="true" value={true}>{t("common.yes")}</option>
                                                                </Field>
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Label for="break" sm={3} xl={12}>{t("screenings.break")}</Label>
                                                            <Col sm={9}>
                                                                <Field component="select" className="form-control" type="text" name="break" normalize={Utils.normalizeBoolean}>
                                                                    <option key="false" value={false}>{t("common.no")}</option>
                                                                    <option key="true" value={true}>{t("common.yes")}</option>
                                                                </Field>
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Label for="version" sm={3} xl={12}>{t("screenings.version")}</Label>
                                                            <Col sm={9}>
                                                                <Field component="input" className="form-control" type="text" name="version" id="version" autoComplete="version" />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Label for="format" sm={3} xl={12}>{t("screenings.format")}</Label>
                                                            <Col sm={9}>
                                                                <Field component="input" className="form-control" type="text" name="format" id="format" autoComplete="format" />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Label for="support" sm={3} xl={12}>{t("screenings.support")}</Label>
                                                            <Col sm={9}>
                                                                <Field component="select" className="form-control" type="text" name="support" id="support">
                                                                    <option key="0" value="">---</option>
                                                                    {
                                                                        Utils.programmation_supports().map((s) => <option key={s} value={s}>{t("programmations.support_" + s)}</option>)
                                                                    }
                                                                </Field>
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Label for="break" sm={3} xl={12}>{t("screenings.ignore_on_maccsbox")}</Label>
                                                            <Col sm={9}>
                                                                <Field component="select" className="form-control" type="text" name="ignore_on_maccsbox" normalize={Utils.normalizeBoolean}>
                                                                    <option key="false" value={false}>{t("common.no")}</option>
                                                                    <option key="true" value={true}>{t("common.yes")}</option>
                                                                </Field>
                                                            </Col>
                                                        </FormGroup>
                                                    </Col>
                                                </FormGroup>
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </TabPane>
            </TabContent>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="2">
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="fa fa-camera"></i>&nbsp;{t("screenings.posters")}</CardTitle>
                                    <CardText tag="div">
                                        <FormGroup row>
                                            <Label for="new_poster" sm={3}>{`${t("screenings.add_poster")}`}</Label>
                                            <Col sm={9}>
                                                <Field component={FileUpload} type="file" className="form-control" name="new_poster" id="new_poster" />
                                                <Button type="submit" color="info" size="sm" disabled={pristine || submitting || isLoading}>
                                                    {isLoading ?
                                                        <span> &nbsp;<i className="fa fa-spinner fa-spin"></i> </span>
                                                        :
                                                        <span> <i className="fa fa-plus"></i>&nbsp; {t("common.add")} </span>
                                                    }
                                                </Button>
                                            </Col>
                                        </FormGroup>
                                        {legacyScreening.posters.length > 0 &&
                                            <FieldArray name="posters" component={ScreeningPosters} posters={legacyScreening.posters} />
                                        }
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </TabPane>
            </TabContent>
            {tktInstance.hasModule(Utils.MODULE_RESOURCES) &&
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="3">
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle><i className="fa fa-id-badge"></i>&nbsp;{t("screenings.tasks")}</CardTitle>
                                        <CardText tag="div">
                                            {legacyScreening && legacyScreening.id &&
                                                <TasksList screening_id={[legacyScreening.id]} />
                                            }
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>
            }
            <Row>
                <Col className="text-center">
                    <ButtonGroup>
                        <Button onClick={goBack} color="warning">
                            <i className="fa fa-arrow-left"></i>&nbsp;{t('common.cancel')}
                        </Button>
                        <Button type="submit" color="success" disabled={pristine || submitting || isLoading}>
                            <span>
                                <i className={`fa ${isLoading ? 'fa-spinner fa-spin' : 'fa fa-save'}`}></i>&nbsp;{t("common.save")}
                            </span>
                        </Button>
                        <Button onClick={(e) => { handleTktSync(); }} color="info" size="sm" disabled={!needsSynchronization}>
                            <i className={`fa ${isTktsyncing ? 'fa-spinner fa-spin' : 'fa-download'}`}></i>
                            &nbsp;{t("events.tktsync")}
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
        </Form>
    );
}

let mapDispatchToProps = {};

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
OldScreeningForm = reduxForm({
    form: 'oldScreeningForm', // a unique identifier for this form
    enableReinitialize: true,
    destroyOnUnmount: false
})(OldScreeningForm)

// You have to connect() to any reducers that you wish to connect to yourself
OldScreeningForm = connect(
    state => ({
        // pull initial values from programmations reducer
        initialValues: state.legacyScreenings.legacyScreening,
        legacyScreenings: state.legacyScreenings
    }), mapDispatchToProps
)(OldScreeningForm)

export default OldScreeningForm;
