import React from 'react';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../services';
import SelectField from '../SelectField/SelectField';

import './PpdsField.css';

const PpdsField = ({ name }) => {
    const { t }   = useTranslation();
    const options = Utils.getPpds().map(ppd => {
        return {
            key: ppd,
            text: t(`printers.ppds.${ppd}`),
            value: ppd
        };
    });

    return (
        <SelectField
            name={ name }
            options={ options }
        />
    );
}

export default PpdsField;
