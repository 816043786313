import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux'
import PageTitle from '../../../components/PageTitle/PageTitle';
import TasksListFilters from './TasksListFilters/TasksListFilters';
import TasksListResume from './TasksListResume/TasksListResume';
import TasksListDetails from './TasksListDetails/TasksListDetails';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { loadUsers } from '../../../redux/modules/users';
import { loadTasks } from '../../../redux/modules/tasks';
import CreateButton from '../../../components/CreateButton';

import './TasksList.css';

const TasksList = ({ screening_id }) => {
    const me                    = useSelector(state => state.auth.user);
    const { tasks }             = useSelector(state => state.tasks);
    const { users }             = useSelector(state => state.users);
    const dispatch              = useDispatch();
    const { t }                 = useTranslation();
    const [filters, setFilters] = useState({
        view_mode: 'details',
        group_type: 'days',
        activity_id: null,
        status: null,
        user_id: null,
        start_at_gte: moment(),
        start_at_lte: moment().add(1, 'month'),
        screening_id: screening_id ?? null,
        needsRemoteUpdate: false
    });

    useEffect(() => {
        dispatch(loadTasks(filters));
        if (me?.can('readOther', 'users') || me?.can('readSelf', 'users'))
            dispatch(loadUsers());
    }, []);

    useEffect(() => {
        if (filters.needsRemoteUpdate)
            dispatch(loadTasks(filters));
    }, [filters])

    useEffect(() => {
        setFilters({
            ...filters,
            screening_id: screening_id
        })
    }, [screening_id])

    const on_change_filters = (values) => {
        values.needsRemoteUpdate = filters.view_mode === values.view_mode && filters.group_type === values.group_type
        setFilters(values)
    }

    const filteredUsers = _.uniqBy(tasks?.map(t => {
        if (t.user)
            return t.user
    }), 'id').filter(f => !!f);

    const filteredActivities = _.uniqBy(tasks?.map(t => {
        return t.activity
    }), 'id');

    const screenings = _.uniqBy(tasks?.map(t => {
        if (t.screening)
            return t.screening
    }), 'id').filter(s => !!s);

    const actions =
        <CreateButton
            module="tasks"
            text="tasks.add"
            customPath={`/tasks/new/${screening_id || ""}`}
        />

    const title = <span>{t("tasks.task_plural")}</span>;

    if (!tasks || !me)
        return null;

    return (
        <div className="TasksList">
            {me &&
                <>
                    <div className="noPrint">
                        <PageTitle icon="id-badge" title={title} actions={actions} />
                        <TasksListFilters
                            users={filteredUsers}
                            screenings={screenings}
                            activities={filteredActivities}
                            onChange={on_change_filters}
                            screening_id={screening_id}
                        />
                    </div>
                    {filters.view_mode === 'resume' &&
                        <TasksListResume tasks={tasks} filters={filters} />
                    }
                    {filters.view_mode === 'details' &&
                        <TasksListDetails
                            tasks={tasks}
                            filters={filters}
                            screenings={screenings}
                            users={users ?? filteredUsers ?? []}
                            me={me}
                        />
                    }
                </>
            }
        </div>
    );
}

export default TasksList;
