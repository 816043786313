import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
import { Input } from 'reactstrap';
import { Link } from "react-router-dom";
import PageTitle from '../../../components/PageTitle/PageTitle';
import matchSorter from 'match-sorter';
import 'react-table-6/react-table.css'
import { loadPricinglists, duplicatePricinglist } from '../../../redux/modules/pricinglists';
import Table from '../../../components/Table/Table';
import TableButtons from '../../../components/TableButtons';
import CreateButton from '../../../components/CreateButton';

import './PricinglistsList.css';

const PricinglistsList = () => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    const { duplicatedPricinglist } = useSelector(state => state.pricinglists);

    useEffect(() => {
        dispatch(loadPricinglists());
    }, [dispatch, duplicatedPricinglist]);

    const onDuplicate = (pricinglist) => {
        dispatch(duplicatePricinglist(pricinglist));
    }

    const { pricinglists } = useSelector(state => state.pricinglists);

    const textFilter = ({ filter, onChange }) =>
        <Input
            type="text"
            onChange={event => onChange(event.target.value)}
        />;
    const textFilterMethod = (key) => (filter, rows) => matchSorter(rows, filter.value, { keys: [key] });

    const textOrIdFilterMethod = (filter, rows) => matchSorter(rows, filter.value, { keys: ["name", "_id"] });

    const columns = [{
        Header: t("pricinglists.name"),
        accessor: "name",
        filterMethod: textOrIdFilterMethod,
        Filter: textFilter,
        filterAll: true,
        Cell: row => (
            <Link to={`/pricinglists/edit/${row.original._id}`}>
                <div className="name-cell px-3 py-1 d-flex justify-content-between">
                    {row.original.name}
                    <span className="badge badge-info p-2">{row.original._id}</span>
                </div>
            </Link>
        )
    }, {
        Header: t("pricinglists.nb_pricings"),
        accessor: "nb_pricings",
        filterMethod: textFilterMethod("number"),
        Filter: textFilter,
        Cell: row => <span>{Object.keys(row.original.pricings).length}&nbsp;{t()}</span>,
        filterable: false,
        filterAll: true
    }, {
        Header: t("common.actions"),
        accessor: "_id",
        maxWidth: 110,
        filterable: false,
        Cell: row => (
            <TableButtons
                id={row.value}
                actions={["edit", "delete", "duplicate"]}
                module="pricinglists"
                onDuplicate={() => onDuplicate(row.original)}
            />
        )
    }];

    const actions =
        <CreateButton
            module="pricinglists"
            text="pricinglists.add"
        />

    const title =
        <span>{t("pricinglists.pricinglist_plural")}</span>

    return (
        <div className="PricinglistsList">
            <PageTitle icon="usd" title={title} actions={actions} />
            <Table id="pricingListsTable" data={pricinglists} columns={columns} />
        </div>
    );
}

export default PricinglistsList;
