import React from 'react';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../services';
import MultiSelectField from '../MultiSelectField/MultiSelectField';

import './TktUsersRolesField.css';

const TktUsersRolesField = ({ name }) => {
    const { t }   = useTranslation();

    const options = Utils.tktUserRoles().map((r, index) => {
        return {
            key: r,
            text: t("roles." + r),
            value: r,
            "data-tooltip": t("tktUsers.roles_tooltips." + r),
            "data-position": index < 3 ? "bottom center" : "top center",
            className: "rolesTooltips"
        };
    });

    return <MultiSelectField name={ name } options={ options } placeholder={t("users.role_placeholder")} hasToolTip/>
}

export default TktUsersRolesField;
