import BaseModel from './BaseModel';

/** Class representing a Ref. */
class Ref extends BaseModel {
    display = () => {
        return [this.id, this.name].filter(Boolean).join(' - ');
    }
}

export default Ref;
