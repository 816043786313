import RestClient from './RestClient';
import { Task } from '../../models';
import moment from 'moment';

class Tasks extends RestClient {
    constructor() {
        super({
            "model": Task,
            "entryPoint": "task",
            "resource": "task"
        });
    }

    list(params) {
        let url = '/task/list';
        ['screening_id', 'activity_id', 'user_id', 'status'].forEach((key) => {
            if (params[key])
                url += `/${key}/${params[key]}`;
        });
        ['start_at_lte', 'start_at_gte', 'stop_at_lte', 'stop_at_gte'].forEach((key) => {
            if (params[key])
                url += `/${key}/${moment(params[key]).toISOString()}`;
        });
        return this.GET(url)
            .then(response => response.json())
            .then(json => {
                if (json.tasks)
                    return json;
                throw new Error(json.flash.error);
            });
    }

    assign(task_id, user_id) {
        let url = `/task/assign/id/${task_id}`;
        return this.PUT(url, { 'user_id': user_id })
            .then(response => response.json())
            .then(json => {
                if (json.task)
                    return json;
                throw new Error(json.flash.error);
            });
    }

    change_status(task_id, status) {
        let url = `/task/change_status/id/${task_id}`;
        return this.PUT(url, { 'status': status })
            .then(response => response.json())
            .then(json => {
                if (json.task)
                    return json;
                throw new Error(json.flash.error);
            });
    }

    accept(task_id) {
        let url = `/task/accept/id/${task_id}`;
        return this.PUT(url)
            .then(response => response.json())
            .then(json => {
                if (json.task)
                    return json;
                throw new Error(json.flash.error);
            });
    }

    claim(task_id) {
        let url = `/task/claim/id/${task_id}`;
        return this.PUT(url)
            .then(response => response.json())
            .then(json => {
                if (json.task)
                    return json;
                throw new Error(json.flash.error);
            });
    }

    refuse(task_id) {
        let url = `/task/refuse/id/${task_id}`;
        return this.PUT(url)
            .then(response => response.json())
            .then(json => {
                if (json.task)
                    return json;
                throw new Error(json.flash.error);
            });
    }

    renounce(task_id, msg) {
        let url = `/task/renounce/id/${task_id}`;
        return this.PUT(url, {msg: msg})
            .then(response => response.json())
            .then(json => {
                if (json.task)
                    return json;
                throw new Error(json.flash.error);
            });
    }
}

const TasksApi = new Tasks();

export default TasksApi;
