import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux'
import { Form, Row, Col, Button, ButtonGroup, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { Field, reduxForm } from 'redux-form'
import { Utils } from '../../../../services/';
import { useTranslation } from 'react-i18next';
import DateTimePicker from '../../../../components/DateTimePicker/DateTimePicker';
import _ from 'lodash';
import moment from 'moment'
import 'moment/locale/fr';
import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css'

import './TasksListFilters.css';

moment.locale('fr');
momentLocalizer();

let TasksListFiltersForm = ({ users, screenings, activities, onChange, screening_id }) => {
    const default_lang          = Utils.default_lang();
    const { t }                 = useTranslation();
    const me                    = useSelector(state => state.auth.user);
    const [filters, setFilters] = useState({
        group_type: 'days',
        view_mode: 'details',
        activity_id: null,
        status: null,
        user_id: null,
        start_at_gte: moment(),
        start_at_lte: moment().add(1, 'month'),
        screening_id: screening_id
    });

    useEffect(() => {
        onChange(filters);
    }, [filters]);

    const toggle_group = (type) => {
        setFilters({
            ...filters,
            group_type: type
        });
    };

    const toggle_view_mode = (view_mode) => {
        setFilters({
            ...filters,
            view_mode: view_mode
        });
    };

    const set_activity_id = (activity_id) => {
        setFilters({
            ...filters,
            activity_id: activity_id
        });
    };

    const set_user_id = (user_id) => {
        setFilters({
            ...filters,
            user_id: user_id,
            activity_id: !user_id ? filters.activity_id : null,
            status: !user_id || (filters.status != Utils.TASK_STATUS_NEW && filters.status != Utils.TASK_STATUS_FREE) ? filters.status : null
        });
    };

    const set_status = (status) => {
        setFilters({
            ...filters,
            status: status
        });
    };

    const set_start_at_gte = (start_at_gte) => {
        setFilters({
            ...filters,
            start_at_gte: moment(start_at_gte).startOf('day')
        });
    };

    const set_start_at_lte = (start_at_lte) => {
        setFilters({
            ...filters,
            start_at_lte: moment(start_at_lte).endOf('day')
        });
    };

    const set_screening_id = (screening_id) => {
        setFilters({
            ...filters,
            screening_id: screening_id
        });
    };

    let filtered_users = users ? _.sortBy(users, u => u.fullname) : [];

    if ((!me.can('editOther', 'tasks') && !me.can('editSelf', 'tasks')))
        filtered_users = [{
            ...me,
            fullname: t('common.me')
        }];

    const filtered_activities = activities.filter((a) => {
        if (filters.user_id)
            return me.activities.map((activity) => activity.id).includes(a.id);
        return true;
    });

    const filtered_status = Utils.taskStatus().filter((s) => {
        if (filters.user_id)
            return s != Utils.TASK_STATUS_NEW && s != Utils.TASK_STATUS_FREE;

        return true;
    });

    return (
        <Form className="ProgrammationForm">
            <div className="TasksListFilters">
                <Row>
                    <Col xs="12" sm="auto" className="group-filters no-print">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">{t('tasks.filters.view_mode')}</InputGroupAddon>
                            <ButtonGroup>
                                <Button color={filters.view_mode === 'details' ? 'info' : 'secondary'} onClick={(e) => toggle_view_mode('details')} title={t("tasks.filters.view_mode_details")}>
                                    <i className="fa fa-th-list"></i>&nbsp;
                                    <span>{t("tasks.filters.view_mode_details")}</span>
                                </Button>
                                <Button color={filters.view_mode === 'resume' ? 'info' : 'secondary'} onClick={(e) => toggle_view_mode('resume')} title={t("tasks.filters.view_mode_resume")}>
                                    <i className="fa fa-tachometer"></i>&nbsp;
                                    <span>{t("tasks.filters.view_mode_resume")}</span>
                                </Button>
                            </ButtonGroup>
                        </InputGroup>
                    </Col>
                    <Col xs="12" sm="auto" className="group-filters no-print">
                        {filters.view_mode === 'details' &&
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">{t('tasks.filters.group')}</InputGroupAddon>
                                <ButtonGroup>
                                    <Button color={filters.group_type === 'days' ? 'info' : 'secondary'} onClick={(e) => toggle_group('days')} title={t("tasks.filters.group_by_days")}>
                                        <i className="fa fa-calendar"></i>&nbsp;
                                        <span>{t("tasks.filters.group_by_days")}</span>
                                    </Button>
                                    <Button color={filters.group_type === 'screenings' ? 'info' : 'secondary'} onClick={(e) => toggle_group('screenings')} title={t("tasks.filters.group_by_screenings")}>
                                        <i className="fa fa-th"></i>&nbsp;
                                        <span>{t("tasks.filters.group_by_screenings")}</span>
                                    </Button>
                                </ButtonGroup>
                            </InputGroup>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col sm="4" className={`user-filters ${!filters.user_id ? 'no-print' : ''}`}>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">{t('tasks.user')}</InputGroupAddon>
                            <Input
                                type="select"
                                className="custom-select"
                                onChange={(e) => set_user_id(e.target.value)}
                            >
                                <option key="0" value="">---</option>
                                {filtered_users && filtered_users.map((u) =>
                                    <option key={u.id} value={u.id}>{u.fullname}</option>
                                )}
                            </Input>
                        </InputGroup>
                    </Col>
                    <Col sm="4" className={`activity-filters ${!filters.activity_id ? 'no-print' : ''}`}>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">{t('tasks.activity')}</InputGroupAddon>
                            <Input
                                type="select"
                                className="custom-select"
                                onChange={(e) => set_activity_id(e.target.value)}
                            >
                                <option key="0" value="">---</option>
                                {filtered_activities && filtered_activities.map((a) =>
                                    <option key={a.id} value={a.id}>{a.name[default_lang]}</option>
                                )}
                            </Input>
                        </InputGroup>
                    </Col>
                    <Col sm="4" className={`status-filters ${!filters.status ? 'no-print' : ''}`}>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">{t('tasks.status')}</InputGroupAddon>
                            <Input
                                type="select"
                                className="custom-select"
                                onChange={(e) => set_status(e.target.value)}
                            >
                                <option key="0" value="">---</option>
                                {filtered_status && filtered_status.map((s) =>
                                    <option key={s} value={s}>{t('tasks.status_' + s)}</option>
                                )}
                            </Input>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm="4" className={`status-filters ${!filters.screening_id ? 'no-print' : ''}`}>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">{t('tasks.screening')}</InputGroupAddon>
                            <Input
                                type="select"
                                className="custom-select"
                                onChange={(e) => set_screening_id(e.target.value)}
                            >
                                <option key="0" value="">---</option>
                                {screenings && screenings.map((s) =>
                                    <option key={s.id} value={s.id}>
                                        {s.title[default_lang] + ' / ' + moment(s.start_at).format('dddd DD MMMM HH:mm')}
                                    </option>
                                )}
                            </Input>
                        </InputGroup>
                    </Col>
                    <Col sm="4" className={`user-filters ${!filters.start_at_gte ? 'no-print' : ''}`}>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">{t('tasks.start_at_gte')}</InputGroupAddon>
                            <Field
                                component={DateTimePicker}
                                viewMode='days'
                                type="text"
                                name="start_at_gte"
                                timeFormat={false}
                                onChange={set_start_at_gte}
                            />
                        </InputGroup>
                    </Col>
                    {filters.start_at_gte &&
                        <Col sm="4" className={`status-filters ${!filters.start_at_lte ? 'no-print' : ''}`}>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">{t('tasks.start_at_lte')}</InputGroupAddon>
                                <Field
                                    component={DateTimePicker}
                                    viewMode='days'
                                    type="text"
                                    name="start_at_lte"
                                    timeFormat={false}
                                    onChange={set_start_at_lte}
                                />
                            </InputGroup>
                        </Col>
                    }
                </Row>
            </div>
        </Form>
    );
}

const mapDispatchToProps = {}

TasksListFiltersForm = reduxForm({
    form: 'tasksListFiltersForm', // a unique identifier for this form
    enableReinitialize: true,
    destroyOnUnmount: false
})(TasksListFiltersForm)

TasksListFiltersForm = connect(
    state => ({
        auth: state.auth
    }), mapDispatchToProps
)(TasksListFiltersForm)

export default TasksListFiltersForm;
