import React, { useEffect, useState, lazy, Suspense} from "react";
import { Row, Col } from "reactstrap";
// import Overview from './Overview'

import GridParams from './GridParams'
import Zone from './Zone'
import ZoneOptions from "./ZoneOptions";
import _ from 'lodash';

import "./PlaceMap.css";
const Overview = lazy(() => import('./Overview'));

const randomColor = require("randomcolor");
const zoneColors = {};

const PlaceMap = ({ input, isLoading }) => {
    const map = input?.value
    const [gridParams, setGridParams]                     = useState({});
    const [gridCells, setGridCells]                       = useState([]);
    const [seats, setSeats]                               = useState(map?.seats);
    const [levelToShow, setLevelToShow]                   = useState('overview');
    const [draggedCellsCoords, setDraggedCellsCoords]     = useState([]);
    const [selectedZonelabel, setSelectedZonelabel]       = useState('');
    const [selectedZoneCategory, setSelectedZoneCategory] = useState('');
    const [selectedZoneColor, setSelectedZoneColor]       = useState('');
    
    const drawCells = () => {
        let newCells = [];
        if (map && map.seats && map.seats.length !== 0) {
            newCells = map.seats.map((data) => {
                return {
                    x: data.position.y,
                    y: data.position.x,
                    zoneCat: data.category,
                    zoneLabel: data.placing.zone,
                    color: getZoneColor(data.placing.zone),
                    additional_class_names: data.additional_class_names ?? "classic",
                    placing : data.placing,
                    seat_number: parseInt(data.placing.seat_number, 10),
                    row: _.isNaN(parseInt(data.placing.row, 10)) ? data.placing.row : parseInt(data.placing.row, 10),
                    position: data.position,
                };
            });
        }

        if (gridParams.nbRows !== 0 && gridParams.nbColumns !== 0) {
            for (var i = 0; i < gridParams.nbRows; i++) {
                for (var j = 0; j < gridParams.nbColumns; j++) {
                    if (!_.some(newCells, { x: i, y: j }))
                        newCells.push({
                            x: i,
                            y: j,
                            zoneCat: "",
                            zoneLabel: "",
                            color: "transparent",
                            additional_class_names: "classic",
                            position: {
                                z: 0,
                                y: i,
                                x: j,
                            },
                        });
                }
            }
            _.remove(newCells, e => e.x >= gridParams.nbRows || e.y >= gridParams.nbColumns);
        }
        setGridCells(newCells);
    }

    useEffect(() => {
        setTimeout(() => {
            const yPosition = _(map.seats).map('position.y').flatten().max()
            const xPosition = _(map.seats).map('position.x').flatten().max()
            onGridParamsChanged({
                nbColumns: xPosition ?  xPosition + 1 : 10,
                nbRows: yPosition ?  yPosition + 1 : 10,
                stagePosition: map.stage_position || 'top'
            })
        }, 100);
    }, []);

    useEffect(() => {
        drawCells();
    }, [gridParams]);

    useEffect(() => {
        if (gridCells && gridCells.length) {
            setSeats(computeSeatsFromCells());
        }
    }, [JSON.stringify(gridCells)]);

    useEffect(() => {
        saveChanges();
    }, [ seats ]);

    const saveChanges = () => {
        const seats = computeSeatsFromCells();
        if (seats && seats.length) {
            input.onChange({
                ...map,
                seats,
                stage_position: gridParams.stagePosition,
                dim: {
                    x: parseInt(gridParams.nbColumns, 10),
                    y: parseInt(gridParams.nbRows, 10),
                    z: 1
                }
            });
        }
    };

    const computeSeatsFromCells = () => {
        const seats = [];
        gridCells.map((data) => {
            if (data.zoneLabel !== '' && data.zoneCat !== '') {
                seats.push({
                    category: data.zoneCat,
                    label: `${data.zoneLabel}:${data.placing.row}:${data.placing.seat_number}`,
                    additional_class_names: data.additional_class_names,
                    placing: {
                        row: '' + data.placing.row,
                        seat_number: '' + data.placing.seat_number,
                        zone: data.placing.zone,
                    },
                    position: {
                        z: 0,
                        y: data.x,
                        x: data.y,
                    },
                });
            }
        });
        return seats;
    }

    const onGridParamsChanged = (gridParams) => {
        setGridParams(gridParams);
        //drawCells();
        input.onChange({
            ...map,
            stage_position: gridParams.stagePosition,
            dim: {
                x: parseInt(gridParams.nbColumns, 10),
                y: parseInt(gridParams.nbRows, 10),
                z: 1
            }
        });
    }

    const getZoneColor = (zoneLabel) => {
        if (!zoneColors[zoneLabel])
            zoneColors[zoneLabel] = randomColor({
                luminosity: "light",
                hue: "random",
            });

        return zoneColors[zoneLabel];
    };

    const onZoneCreated = (cells) => {
        setGridCells(cells);
        setLevelToShow('overview');
    }

    const onDragFinished = (draggedCellsCoords) => {
        if (draggedCellsCoords !== undefined && draggedCellsCoords.length === 0) {
            return
        } 
        setDraggedCellsCoords(draggedCellsCoords);
        setLevelToShow('zoneOptions');
    }

    const onZoneSelected = (cell) => {
        setSelectedZonelabel(cell.zoneLabel);
        setSelectedZoneCategory(cell.zoneCat)
        setSelectedZoneColor(cell.color)
        setLevelToShow('zone');
    }

    return (
        <div className="PlaceMap">
            { levelToShow === 'overview' && (
                <Row>
                    <Col sm={ 9 }>
                        <div>
                            <Suspense fallback={<div>Loading Component</div>}>
                               <Overview
                                    gridCells={ gridCells }
                                    seats={ seats }
                                    gridParams={ gridParams }
                                    onDragFinished={ onDragFinished }
                                    onZoneSelected={ onZoneSelected }
                                    isLoading={ isLoading }
                                />
                            </Suspense>
                        </div>
                    </Col>
                    <Col sm={ 3 }>
                        <GridParams
                            gridParamsObj={ gridParams }
                            onChange={ onGridParamsChanged }
                        />
                    </Col>
                </Row>
            )}

            { levelToShow === 'zoneOptions' && (
                <ZoneOptions
                    gridCells={ gridCells }
                    draggedCellsCoords={ draggedCellsCoords }
                    onSubmit={ onZoneCreated }
                    onClose={() => setLevelToShow('overview')}
                />
            )}

            { levelToShow === 'zone' && (
                <Zone
                    gridCells ={ gridCells }
                    selectedZone={{
                        zoneLabel: selectedZonelabel,
                        zoneCat: selectedZoneCategory,
                        color: selectedZoneColor
                    }}
                    globalNbColumns = { gridParams.nbColumns }
                    globalNbRows = { gridParams.nbRows }
                    onSubmit={(cells) => {
                        setGridCells(cells);
                        setLevelToShow('overview')
                        // setIsGridValueChange(!isGridValueChange)
                    }}
                    onClose={() => setLevelToShow('overview')
                    }
                />
            )}

        </div>
    );
}

export default PlaceMap;
