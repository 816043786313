import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap';
import { Link } from "react-router-dom";
import PageTitle from '../../../components/PageTitle/PageTitle';
import { useTranslation } from 'react-i18next';
import { Filters, Utils } from '../../../services';
import 'react-table-6/react-table.css'
import { loadEvents } from '../../../redux/modules/events';
import Table from '../../../components/Table/Table';
import TableButtons from '../../../components/TableButtons';
import CreateButton from '../../../components/CreateButton';
import { EventTitle } from '../../../components';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

import './EventsList.css';

const EventsList = () => {
    const { events }   = useSelector(state => state.events);
    const { t }        = useTranslation()
    const { type }     = useParams();
    const dispatch     = useDispatch();
    const default_lang = Utils.default_lang();

    useEffect(() => {
        dispatch(loadEvents(type))
    }, [type])

    const columns = useMemo(() => {
        const temp = [{
            Header: t("events.title"),
            accessor: "title",
            filterMethod: Filters.textFilterMethod([`title.${default_lang}`, '_original.original_title']),
            Filter: Filters.textFilter,
            filterAll: true,
            maxWidth: 300,
            Cell: row => row.value && (
                <div className="event_cell">
                    <Link to={`/events/${type}/edit/${row.original.id}`}>
                        <Card>
                            <CardBody style={{
                                "backgroundImage": "url('" + (
                                    row.original.posters && row.original.posters.length ?
                                        row.original.posters[0].url : ""
                                ) + "')",
                                "backgroundPosition": "center center",
                                "backgroundSize": "cover"
                            }}>
                                <EventTitle titles={row.value} original={row.original.original_title} />
                            </CardBody>
                        </Card>
                    </Link>
                </div>
            )
        }];

        if (type === Utils.EVENT_TYPE_MOVIE) {
            temp.push({
                Header: t("events.suisa"),
                accessor: 'suisa',
                filterMethod: Filters.textFilterMethod('suisa'),
                Filter: Filters.textFilter,
                filterAll: true,
            });
        }

        const second_column = type === Utils.EVENT_TYPE_SERVICE ? "service_type" : "genre";
        temp.push({
            Header: t("events." + second_column),
            accessor: second_column,
            Cell: row => (
                type === Utils.EVENT_TYPE_SERVICE ? Utils.service_types(row.value).label : row.value
            ),
            filterMethod: Filters.textFilterMethod(second_column),
            Filter: Filters.textFilter,
            filterAll: true,
        }, {
            Header: t("common.actions"),
            accessor: "id",
            maxWidth: 110,
            filterable: false,
            Cell: row => {
                return (
                    <TableButtons
                        id={row.value}
                        actions={["edit", "delete"]}
                        module={`events/${type}`}
                        baseModule="events"
                        owner={row.original.created_by}
                    />
                )
            }
        });

        return temp;
    }, [events])

    const actions =
        <CreateButton
            module={`events/${type}`}
            baseModule="events"
            text="events.add"
        />

    const title =
        <span>{t("events.event_plural")}</span>

    return (
        <div className="EventsList">
            <PageTitle icon="th" title={title} actions={actions} />
            <Table id="eventsTable" data={events} columns={columns} />
        </div>
    );
}

export default EventsList;
