import React from "react";
import PdfLayout from '../PdfLayout';
import CustomTextWidget from './CustomTextWidget';
import moment from 'moment';
import { Utils } from '../../services';
import { useTranslation } from 'react-i18next';

/**
 * Class representing a PDF layout widget showing
 * an information about the ticket.
 */
class TicketInfoWidget extends CustomTextWidget {

    constructor(properties) {
        super({
            ...(TicketInfoWidget.getDefaultOptions()),
            ...properties,
        });

        this.type    = PdfLayout.TEXT;
        this.subType = PdfLayout.TICKET_INFO;
    }

    /**
     * Get this widget default options
     */
    static getDefaultOptions() {
        return {
            position: { x: 2, y: 2, z: 0 },
            dimensions : { width: 5, height: 0.75 },
            transform: { rotation: 0 },
            options: {
                field: 'ticket_id',
                style: {
                    color: '#000000',
                    fontFamily: "Helvetica",
                    fontSize: 14,
                    lineHeight: 1,
                    textAlign: 'left',
                    textDecoration: 'none',
                    backgroundColor: '#FFFFFF00',
                    borderStyle: 'solid',
                    borderWidth: 0,
                    borderColor: '#000000',
                    borderRadius: 0,
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    }
                },
            }
        };
    }

    /**
     * Return the list of options this widget handles
     */
    getHandledOptions() {
        const handledOptions = super.getHandledOptions().concat([
            PdfLayout.OPTION_TICKET_FIELD
        ]).filter(o => o !== PdfLayout.OPTION_TEXT);

        if (['activated_at', 'first_window', 'last_window', 'first_window_last_window'].includes(this.options?.field))
            handledOptions.push(PdfLayout.OPTION_DATE_FORMAT);

        return handledOptions;
    }

    /**
     * Return this widget preview as JSX string
     *
     * @param {Object} props with at least the following properties:
     *                 - {Bool} inEdition
     *                 - {String} lang
     *                 - {Object} setting
     */
    getPreviewText(props) {
        const { pricing } = props;
        const { t }       = useTranslation();
        let lang = props?.lang.length > 0 ? props.lang : 'fr';

        switch (this.options.field) {
            case 'ticket_id':
                return [
                    '<span class="tkt-ticketid_ticket">Ticket</span>',
                    '<span class="tkt-ticketid_id">ID</span>',
                    '<span class="tkt-ticketid_sep">:</span>',
                    '<span class="tkt-ticketid_ticket_value">123456</span>',
                    '-',
                    '<span class="tkt-ticketid_id_value">abcdef</span>'
                ].join('');
            case 'ticket_id_only':
                return [
                    '<span class="tkt-ticketid_ticket_value">123456</span>',
                    '-',
                    '<span class="tkt-ticketid_id_value">abcdef</span>'
                ].join('');
            case 'ticket_id_festivalscope':
                return [
                    '<span class="tkt-ticketid_ticket_value">123456</span>',
                    '<span class="tkt-ticketid_id_value">abcdef</span>'
                ].join('');
            case 'uuid':
                return '12345678-1234-4321-1234-123456789012';
            case 'activated_at':
                return this.options?.date_format ? moment('01/12/1970').locale(lang).format(this.options.date_format) : moment('01/12/1970').locale(lang).format('L');
            case 'activated_pricing_name':
                return Utils.localized_or_fallback(pricing.name, lang, t('models.pricing.name'));
            case 'activated_pricing_description':
                return Utils.localized_or_fallback(pricing.description, lang, t('models.pricing.description'));
            case 'activated_pricing_price':
                return pricing.price ? Object.values(pricing.price) + ' ' + Object.keys(pricing.price) : '12 CHF';
            case 'vat':
                return (pricing?.VAT > 0 ?  pricing.VAT  : '0') + '%';
            case 'first_window':
                return this.options?.date_format ? moment('01/12/1970').locale(lang).format(this.options.date_format) : moment('01/12/1970').locale(lang).format('L');
            case 'last_window':
                return this.options?.date_format ? moment('02/12/1970').locale(lang).format(this.options.date_format) : moment('02/12/1970').locale(lang).format('L');
            case 'first_window_last_window':
                return [
                    this.options?.date_format ? moment('01/12/1970').locale(lang).format(this.options.date_format) : moment('01/12/1970').locale(lang).format('L'),
                    this.options?.date_format ? moment('02/12/1970').locale(lang).format(this.options.date_format) : moment('02/12/1970').locale(lang).format('L')
                ].join(' - ');
            default:
                return '...';
        }
    }

    /**
     * Return the icon to use on the editor button
     */
    getIcon() {
        return 'tag';
    }
}

export default TicketInfoWidget;
