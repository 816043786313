import React from 'react';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../services';
import SelectField from '../SelectField/SelectField';
import MultiSelectField from '../MultiSelectField/MultiSelectField';
import i18n from '../../locales/i18n';

import './PricingsField.css';

const PricingsField = ({ pricings, onChange }) => {
    const { t } = useTranslation();

    if (!pricings)
        return null;

    const options = pricings.map(pricing => {
        return {
            key: pricing.id,
            text: Utils.localized_or_fallback(pricing.name, i18n.language),
            value: pricing.id
        };
    });

    const onFieldChange = (value) => {
        onChange(value);
    }

    return (
        <SelectField
            options={ options }
            placeholder={ t('pricinglists.pricing.pricing_choice') }
            onSelectChange={ onFieldChange }
            clearable
            noField
        />
    )
}

export default PricingsField;
