import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Link } from "react-router-dom";
import PageTitle from '../../../components/PageTitle/PageTitle';
import { withTranslation } from 'react-i18next';

import { loadPlace } from '../../../redux/modules/places';

class PlaceShow extends Component<{}> {
    componentDidMount() {
        const placeId = this.props.match.params.placeId;
        // Load places on component mount
        this.props.dispatchLoadPlace(placeId);
    }

    render() {
        const { place } = this.props.places;

        const { t } = this.props;

        let title = <span>{t("places.place_plural")}</span>;
        if (place)
            title = (
                <span>
                    {t("places.place")}
                    <small> | { place.name } </small>
                </span>
            );

        const actions =
        <div>
            <ButtonGroup>
                <Button tag={Link} to="/places" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t("common.back")}
                </Button>
                {place &&
                    <Button tag={Link} to={ `/places/edit/${ place._id }` } color="info" size="sm">
                        <i className="fa fa-edit"></i>&nbsp;{t("common.edit")}
                    </Button>
                }
            </ButtonGroup>
        </div>

        return (
            <div className="PlaceShow">
                <PageTitle icon="th" title={ title } actions={ actions } />
                {place &&
                    <div>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle><i className="fa fa-place"></i>&nbsp;{t("places.informations")}</CardTitle>
                                        <CardText tag="div">
                                            <div><b>{t("places.building")}</b>: { place.cinema }</div>
                                            <div><b>{t("places.name")}</b>: { place.name }</div>
                                            <div><b>{t("places.number")}</b>: { place.number }</div>
                                            <div><b>{t("places.address")}</b>: { place.address }</div>
                                            <div><b>{t("places.zip")}</b>: { place.zip }</div>
                                            <div><b>{t("places.city")}</b>: { place.city }</div>
                                            <div><b>{t("places.state")}</b>: { place.state }</div>
                                            <div><b>{t("places.country")}</b>: { place.country }</div>
                                            <div><b>{t("places.capacity")}</b>: { place.opaque ? place.opaque.capacity : 0 }</div>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadPlace: (placeId) => loadPlace(placeId)
}

const mapStateToProps = state => ({
    places: state.places
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translations")(PlaceShow))
