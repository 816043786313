/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux'
import { FieldArray, Field, reduxForm } from 'redux-form'
import { Link } from "react-router-dom";
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { Form, FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../../services';
import { FileUpload, WysiwygField, TranslatableField, CountriesField, VideoField } from '../../../components';
import EventPeople from './EventPeople/EventPeople';
import EventPosters from './EventPosters/EventPosters';
import EventTrailers from './EventTrailers/EventTrailers';
import classnames from 'classnames';
import ProgrammationsList from '../../Programmations/ProgrammationsList/ProgrammationsList';
import { getEventsLinkedResources } from '../../../redux';
import moment from 'moment'
import 'moment/locale/fr';
import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css'
import i18n from '../../../locales/i18n';

import './EventForm.css';

moment.locale('fr');
momentLocalizer();

let EventForm = ({ handleSubmit, pristine, submitting, isCreation, type }) => {
    const { t }                                 = useTranslation();
    const dispatch                              = useDispatch();
    const [activeTab, setActiveTab]             = useState('1');
    const { isLoading, event, linkedResources } = useSelector(state => state.events);
    const distributors                          = linkedResources?.distributors ?? [];

    useEffect(() => {
        if (type === Utils.EVENT_TYPE_MOVIE && !isCreation)
            dispatch(getEventsLinkedResources())
    }, [dispatch])

    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }

    return (
        <Form onSubmit={handleSubmit} className="EventForm">
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => { toggle('1'); }}
                    >
                        <i className="fa fa-info-circle"></i>&nbsp;
                        {t("events.informations")}
                    </NavLink>
                </NavItem>
                {!isCreation &&
                    <>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '2' })}
                                onClick={() => { toggle('2'); }}
                            >
                                <i className="fa fa-users"></i>&nbsp;
                                {t("events.casting")}
                            </NavLink>
                        </NavItem>
                        {type === Utils.EVENT_TYPE_MOVIE &&
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '3' })}
                                    onClick={() => { toggle('3'); }}
                                >
                                    <i className="fa fa-film"></i>&nbsp;
                                    {t("events.details")}
                                </NavLink>
                            </NavItem>
                        }
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '4' })}
                                onClick={() => { toggle('4'); }}
                            >
                                <i className="fa fa-comments-o"></i>&nbsp;
                                {t("events.free_texts")}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '5' })}
                                onClick={() => { toggle('5'); }}
                            >
                                <i className="fa fa-camera"></i>&nbsp;
                                {t("events.posters")} ({event.posters && event.posters.length || 0})
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '6' })}
                                onClick={() => { toggle('6'); }}
                            >
                                <i className="fa fa-film"></i>&nbsp;
                                {t("events.trailers")} ({event.trailers && event.trailers.length || 0})
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '7' })}
                                onClick={() => { toggle('7'); }}
                            >
                                <i className="fa fa-calendar-plus-o"></i>&nbsp;
                                {t("events.scheduling")}
                            </NavLink>
                        </NavItem>
                    </>
                }
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="fa fa-info-circle"></i>&nbsp;{t("events.informations")}</CardTitle>
                                    <CardText tag="div">
                                        <FormGroup row>
                                            <Label for="title" sm={3}>{t("events.title")}</Label>
                                            <Col sm={9}>
                                                <TranslatableField name="title" placeholder={t("events.title_placeholder")} component="input" />
                                            </Col>
                                        </FormGroup>
                                        {type === Utils.EVENT_TYPE_MOVIE &&
                                            <FormGroup row>
                                                <Label for="original_title" sm={3}>{t("events.original_title")}</Label>
                                                <Col sm={9}>
                                                    <Field component="input" className="form-control" type="text" name="original_title" id="original_title" placeholder={t("events.original_title_placeholder")} autoComplete="original_title" />
                                                </Col>
                                            </FormGroup>
                                        }
                                        {isCreation &&
                                            <FormGroup row>
                                                <Label for="type" sm={3}>{t("events.type")}</Label>
                                                <Col sm={9}>
                                                    <Field required component="select" className="form-control" type="text" name="type" id="type">
                                                        <option key="0" value="">---</option>
                                                        {
                                                            Utils.event_types().map((t) => <option key={t.type} value={t.type}>{t.label}</option>)
                                                        }
                                                    </Field>
                                                </Col>
                                            </FormGroup>
                                        }
                                        {!isCreation &&
                                            <div>
                                                <FormGroup row>
                                                    <Label for="description" sm={3}>{t("events.description")}</Label>
                                                    <Col sm={9}>
                                                        <TranslatableField name="description" placeholder={t("events.description_placeholder")} component={WysiwygField} />
                                                    </Col>
                                                </FormGroup>
                                                {type !== Utils.EVENT_TYPE_SERVICE &&
                                                    <FormGroup row>
                                                        <Label for="genre" sm={3}>{t("events.genre")}</Label>
                                                        <Col sm={9}>
                                                            <Field component="input" className="form-control" type="text" name="genre" id="genre" autoComplete="genre" />
                                                        </Col>
                                                    </FormGroup>
                                                }
                                                {type === Utils.EVENT_TYPE_SERVICE &&
                                                    <FormGroup row>
                                                        <Label for="service_type" sm={3}>{t("events.service_type")}</Label>
                                                        <Col sm={9}>
                                                            <Field component="select" className="form-control" type="text" name="service_type" id="service_type">
                                                                {
                                                                    Utils.service_types().map((t) => <option key={t.type} value={t.type}>{t.label}</option>)
                                                                }
                                                            </Field>
                                                        </Col>
                                                    </FormGroup>
                                                }
                                            </div>
                                        }
                                        <FormGroup row>
                                            <Label for="duration" sm={3}>{t("events.duration")}</Label>
                                            <Col sm={9}>
                                                <Field required min="1" component="input" className="form-control" type="number" name="duration" id="duration" autoComplete="duration" />
                                            </Col>
                                        </FormGroup>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </TabPane>
            </TabContent>
            {!isCreation &&
                <>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="2">
                            <Row>
                                <Col sm={12}>
                                    <Card>
                                        <CardBody>
                                            <CardTitle><i className="fa fa-users"></i>&nbsp;{t("events.casting")}</CardTitle>
                                            <CardText tag="div">
                                                <FormGroup row>
                                                    <Label for="people" sm={3}>{`${t("events.casting")}`}</Label>
                                                    <Col sm={9}>
                                                        <FieldArray name="people" component={EventPeople} people={event.people} />
                                                    </Col>
                                                </FormGroup>
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                    {type === Utils.EVENT_TYPE_MOVIE &&
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="3">
                                <Row>
                                    <Col sm={12}>
                                        <Card>
                                            <CardBody>
                                                <CardTitle><i className="fa fa-film"></i>&nbsp;{t("events.details")}</CardTitle>
                                                <CardText tag="div">
                                                    <FormGroup row>
                                                        <Label for="suisa" sm={3}>{`${t("events.suisa")}`}</Label>
                                                        <Col sm={9}>
                                                            <Field component="input" className="form-control" type="text" name="suisa" id="suisa" autoComplete="suisa" placeholder={t("events.suisa_placeholder")} />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label for="movie_link" sm={3}>{`${t("events.movie_link")}`}</Label>
                                                        <Col sm={9}>
                                                            <Field component="input" className="form-control" type="text" name="movie_link" id="movie_link" autoComplete="movie_link" placeholder={t("events.movie_link_placeholder")} />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label for="distributor_id" sm={3}>{`${t("events.distributor")}`}</Label>
                                                        <Col sm={9}>
                                                            <Field component="select" className="form-control" type="text" name="distributor_id" id="distributor_id">
                                                                <option key="" value="">---</option>
                                                                {distributors &&
                                                                    distributors
                                                                        .sort((a,b) => a.name.localeCompare(b.name))
                                                                        .map((d) =>
                                                                            <option key={d.id} value={d.id}>{d.name}</option>
                                                                        )
                                                                }
                                                            </Field>
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label for="year" sm={3}>{`${t("events.year")}`}</Label>
                                                        <Col sm={9}>
                                                            <Field component="input" className="form-control" type="number" name="year" id="year" autoComplete="year" placeholder={t("events.year_placeholder")} />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label for="countries" sm={3}>{`${t("events.countries")}`}</Label>
                                                        <Col sm={9}>
                                                            <CountriesField name="countries" />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label for="original_language" sm={3}>{`${t("events.original_language")}`}</Label>
                                                        <Col sm={9}>
                                                            <Field component="select" className="form-control" type="text" name="original_language" id="original_language">
                                                                <option key="0" value="">---</option>
                                                                {
                                                                    Utils.sorted_standard_languages(i18n.language).map(l => {
                                                                        return <option key={l.code} value={l.code}>{l.language}</option>
                                                                    })
                                                                }
                                                            </Field>
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label for="l_min_age" sm={3}>{`${t("events.l_min_age")}`}</Label>
                                                        <Col sm={9}>
                                                            <Field component="input" className="form-control" type="text" name="l_min_age" id="l_min_age" autoComplete="l_min_age" placeholder={t("events.l_min_age_placeholder")} />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label for="s_min_age" sm={3}>{`${t("events.s_min_age")}`}</Label>
                                                        <Col sm={9}>
                                                            <Field component="input" className="form-control" type="text" name="s_min_age" id="s_min_age" autoComplete="s_min_age" placeholder={t("events.s_min_age_placeholder")} />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label for="a_min_age" sm={3}>{`${t("events.a_min_age")}`}</Label>
                                                        <Col sm={9}>
                                                            <Field component="input" className="form-control" type="text" name="a_min_age" id="a_min_age" autoComplete="a_min_age" placeholder={t("events.a_min_age_placeholder")} />
                                                        </Col>
                                                    </FormGroup>
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    }
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="4">
                            <Row>
                                <Col sm={12}>
                                    <Card>
                                        <CardBody>
                                            <CardTitle><i className="fa fa-comments-o"></i>&nbsp;{t("events.free_texts")}</CardTitle>
                                            <CardText tag="div">
                                                <FormGroup row>
                                                    <Label for="free_text_1" sm={3}>{`${t("events.free_text")} 1`}</Label>
                                                    <Col sm={9}>
                                                        <TranslatableField name="free_text_1" placeholder="" component={WysiwygField} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="free_text_2" sm={3}>{`${t("events.free_text")} 2`}</Label>
                                                    <Col sm={9}>
                                                        <TranslatableField name="free_text_2" placeholder="" component={WysiwygField} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="free_text_3" sm={3}>{`${t("events.free_text")} 3`}</Label>
                                                    <Col sm={9}>
                                                        <TranslatableField name="free_text_3" placeholder="" component={WysiwygField} />
                                                    </Col>
                                                </FormGroup>
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                </>
            }
            {!isCreation &&
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="5">
                        <Row>
                            <Col sm={12}>
                                <Card>
                                    <CardBody>
                                        <CardTitle><i className="fa fa-camera"></i>&nbsp;{t("events.posters")}</CardTitle>
                                        <CardText tag="div">
                                            <FormGroup row>
                                                <Label for="new_poster" sm={3}>{`${t("events.add_poster")}`}</Label>
                                                <Col sm={9}>
                                                    <Field component={FileUpload} type="file" className="form-control" name="new_poster" id="new_poster" />
                                                    <Button type="submit" color="info" size="sm" disabled={pristine || submitting || isLoading}>
                                                        {isLoading ?
                                                            <span> &nbsp;<i className="fa fa-spinner fa-spin"></i> </span>
                                                            :
                                                            <span> <i className="fa fa-plus"></i>&nbsp; {t("common.add")} </span>
                                                        }
                                                    </Button>
                                                </Col>
                                            </FormGroup>
                                            {event.posters.length > 0 &&
                                                <FieldArray name="posters" component={EventPosters} posters={event.posters} />
                                            }
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>
            }
            {!isCreation &&
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="6">
                        <Row>
                            <Col sm={12}>
                                <Card>
                                    <CardBody>
                                        <CardTitle><i className="fa fa-film"></i>&nbsp;{t("events.trailers")}</CardTitle>
                                        <CardText tag="div">
                                            <FormGroup row>
                                                <Label for="new_trailer" md={3}>{`${t("events.add_trailer")}`}</Label>
                                                <Col md={9}>
                                                    <VideoField
                                                        inputName="new_trailer"
                                                        selectName="new_trailer_type"
                                                        isLoading={isLoading}
                                                        submitting={submitting}
                                                        pristine={pristine}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </CardText>
                                        {event.trailers.length > 0 &&
                                            <FieldArray name="trailers" component={EventTrailers} trailers={event.trailers} />
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>
            }
            {!isCreation &&
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="7">
                        <Row>
                            <Col sm={12}>
                                <Card>
                                    <CardBody>
                                        <CardTitle><i className="fa fa-calendar-plus-o"></i>&nbsp;{t("events.scheduling")}</CardTitle>
                                        <CardText tag="div">
                                            {event && event.event_id &&
                                                <ProgrammationsList events_ids={[event.event_id]} />
                                            }
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>
            }
            <br />
            <Row>
                <Col className="text-center">
                    <br />
                    <ButtonGroup>
                        <Button tag={Link} to={`/events/${type}`} color="warning">
                            <span><i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}</span>
                        </Button>
                        <Button type="submit" color="success" disabled={pristine || submitting || isLoading}>
                            <span>
                                <i className={`fa ${isLoading ? 'fa-spinner fa-spin' : 'fa fa-save'}`}></i>&nbsp;{t("common.save")}
                            </span>
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
        </Form>
    );
}

const mapDispatchToProps = {}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
EventForm = reduxForm({
    form: 'eventForm', // a unique identifier for this form
    enableReinitialize: true
})(EventForm)

// You have to connect() to any reducers that you wish to connect to yourself

EventForm = connect(
    state => {
        let initialValues = { ...state.events.event };
        if (state.events.event && state.events.event.type === Utils.EVENT_TYPE_MOVIE)
            initialValues.distributor_id = state.events.event.distributor ? state.events.event.distributor.id : null;
        return {
            initialValues,
            events: state.events,
            distributors: state.distributors,
            destroyOnUnmount: false
        };
    }, mapDispatchToProps)(EventForm);

export default EventForm;
