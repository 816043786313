import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { Row, Col, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Form, FormGroup, Label } from 'reactstrap';
import { Utils } from '../../../services';
import { ToggleField, TranslatableField } from '../../../components';
import { withTranslation } from 'react-i18next';

import moment from 'moment'
import 'moment/locale/fr';
import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css'

moment.locale('fr');
momentLocalizer();

class ActivityForm extends Component<{}> {
    render() {
        const { isLoading }                             = this.props.activities;
        const { handleSubmit, pristine, submitting, t } = this.props;
        return (
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col sm={12} lg={6}>
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="fa fa-info-circle"></i>&nbsp;{t("activities.informations")}</CardTitle>
                                    <CardText tag="div">
                                        <FormGroup row>
                                            <Label for="name" sm={3}>{t("activities.name")}</Label>
                                            <Col sm={9}>
                                                <TranslatableField name="name" placeholder={t("activities.name_placeholder")} component="input"/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="is_exclusive" sm={3}>{t("activities.is_exclusive")}</Label>
                                            <Col sm={9}>
                                                <ToggleField name="is_exclusive" />
                                            </Col>
                                        </FormGroup>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col className="text-center">
                            <br/>
                            <Button type="submit" color="success" disabled={pristine || submitting || isLoading}>
                                {isLoading ?
                                    <span>
                                        &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                    </span>
                                    :
                                    <span>
                                        <i className="fa fa-save"></i>&nbsp;
                                        {t("common.save")}
                                    </span>
                                }
                            </Button>
                        </Col>
                    </Row>
                </Form>
        );
    }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
ActivityForm = reduxForm({
  form: 'activityForm' // a unique identifier for this form
})(ActivityForm)

// You have to connect() to any reducers that you wish to connect to yourself
ActivityForm = connect(
  state => ({
    initialValues: state.activities.activity, // pull initial values from activities reducer
    activities: state.activities,
})
)(ActivityForm)

export default withTranslation('translations')(ActivityForm)
