import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux'
import { Field, FieldArray, reduxForm, submit } from 'redux-form';
import { useLocation, Link } from "react-router-dom";
import { Row, Col, ButtonGroup, Button, Card, CardImg, CardBody, CardTitle, CardText, CardFooter, InputGroup, Input } from 'reactstrap';
import { Form, FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../../services/';
import { loadTktInstances, getUsersLinkedResources, applyUserAbilitiesTemplate, removeUserAvatar } from '../../../redux';
import UserActivities from './UserActivities/UserActivities';
import _ from 'lodash';
import moment from 'moment'
import 'moment/locale/fr';
import momentLocalizer from 'react-widgets-moment';
import { DateTimePicker } from 'react-widgets';
import 'react-widgets/dist/css/react-widgets.css'
import {
    FileUpload, PhoneField, EmailField,
    AvailabilitiesField, SectorsField,
    ToggleField, Loader, ApplicationDetails
} from '../../../components';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

import user_avatar from '../../../assets/images/user_avatar.jpg';

import './UserForm.css';
import TktInstanceAbilitiesTemplate from '../../TktInstances/TktInstanceForm/TktInstanceAbilitiesTemplate/TktInstanceAbilitiesTemplate';
moment.locale('fr');
momentLocalizer();

let UserForm = ({ handleSubmit, isCreation, authUser, isLoading, pristine, submitting, user, templateApplied, _activeTab, onActiveTab }) => {
    const { hash }                    = useLocation();
    const { t }                       = useTranslation();
    const dispatch                    = useDispatch();
    const { tktInstances }            = useSelector(state => state.tktInstances);
    const { linkedResources }         = useSelector(state => state.users);
    const [templateId, setTemplateId] = useState(0);
    const [activeTab, setActiveTab]   = useState(hash === '#team' ? '3' : _activeTab);

    const { activities, sectors } = linkedResources;

    useEffect(() => {
        if (authUser?.tkt_instance?.hasModule(Utils.MODULE_RESOURCES) ||
            authUser?.tkt_instance?.hasModule(Utils.MODULE_TEAM)
        )
            dispatch(getUsersLinkedResources(['activities', 'sectors'], { id: user?.id }));

        if (authUser?.abilities.superadmin)
            dispatch(loadTktInstances());
    }, [authUser])

    useEffect(() => {
        if (user?.abilities && _.isEmpty(user.abilities))
            dispatch(applyUserAbilitiesTemplate(Utils.generateAbilities()));
    }, [user])

    const onApply = () => {
        if (templateId)
            dispatch(applyUserAbilitiesTemplate(authUser.tkt_instance.abilities_templates[templateId].abilities));
    }

    const onSelect = (e) => {
        setTemplateId(e.target.value);
    }

    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
            onActiveTab && onActiveTab(tab);
        }
    }

    const renderDateTimePicker = ({ input: { onChange, value }, showTime }) =>
        <DateTimePicker
            onChange={onChange}
            format="DD MMM YYYY"
            time={showTime}
            value={!value ? null : new Date(value)}
        />

    const removeAvatar = () => {
        dispatch(removeUserAvatar());
        dispatch(submit('userForm'));
    }

    let optionsAbilities = authUser?.tkt_instance?.abilities_templates?.map((template, index) => {
        return [
            <option
                key={index}
                value={index}
            >
                {template.name}
            </option>
        ];
    });

    return (
        <Form className="form" onSubmit={handleSubmit}>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => { toggle('1'); }}
                    >
                        <i className="fa fa-info-circle"></i>&nbsp;
                        {t("users.informations")}
                    </NavLink>
                </NavItem>
                {!isCreation && authUser.can('read_abilities', 'users') && (
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '2' })}
                            onClick={() => { toggle('2'); }}
                        >
                            <i className="fa fa-sliders"></i>&nbsp;
                            {t("users.user_abilities")}
                        </NavLink>
                    </NavItem>
                )}
                {!isCreation &&
                (authUser?.tkt_instance?.hasModule(Utils.MODULE_RESOURCES) || authUser?.tkt_instance?.hasModule(Utils.MODULE_TEAM)) &&
                    <NavItem>
                        <NavLink
                        className={classnames({ active: activeTab === '3' })}
                        onClick={() => { toggle('3'); }}
                        >
                            <i className="fa fa-id-badge"></i>&nbsp;
                            {t("users.activities")}
                        </NavLink>
                    </NavItem>
                }
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <Row>
                        <Col xs={12} xl={2}>
                            <div className="mt-3" style={{ maxWidth: '250px', margin: '0 auto' }}>
                                <Card>
                                    <CardImg top src={ user?.avatarUrl()  || user_avatar } alt="Card image cap" />
                                    <CardFooter>
                                        {user?.avatar ? (
                                            <Button color="danger" size="sm" block onClick={ removeAvatar }>
                                                <i className="fa fa-trash"></i>&nbsp;{t("common.delete")}
                                            </Button>
                                        ) : (
                                            <Field component={ FileUpload } type="file" className="form-control" name="new_avatar" id="new_avatar" />
                                        )}
                                    </CardFooter>
                                </Card>
                            </div>
                        </Col>
                        <Col xs={12} xl={10}>
                            <Row className="main mt-3">
                                <Col sm={12} lg={6}>
                                    <Card>
                                        <CardBody>
                                            <CardTitle>
                                                <i className="fa fa-user-circle"></i>&nbsp;
                                                {t('users.profile')}
                                            </CardTitle>
                                            <CardText tag="div">
                                                <FormGroup row>
                                                    <Label for="firstname" sm={3}>{t('users.firstname')}</Label>
                                                    <Col sm={9}>
                                                        <Field required component="input" className="form-control" type="text" name="firstname" id="firstname" autoComplete="off" placeholder={t('users.firstname_placeholder')} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="lastname" sm={3}>{t('users.lastname')}</Label>
                                                    <Col sm={9}>
                                                        <Field required component="input" className="form-control" type="text" name="lastname" id="lastname" autoComplete="off" placeholder={t('users.lastname_placeholder')} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="new-password" sm={3}>{t('users.password')}</Label>
                                                    <Col sm={9}>
                                                        <Field required={isCreation} component="input" className="form-control" type="password" name="new-password" id="new-password" autoComplete="off" placeholder={t('users.password_placeholder')} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="new-password-confirmation" sm={3}>{t('users.password_confirmation')}</Label>
                                                    <Col sm={9}>
                                                        <Field required={isCreation} component="input" className="form-control" type="password" name="new-password-confirmation" id="new-password-confirmation" autoComplete="off" placeholder={t('users.password_placeholder')} />
                                                    </Col>
                                                </FormGroup>
                                                {(!isCreation && authUser.isSuperAdmin()) &&
                                                    <FormGroup row>
                                                        <Label for="hidden" sm={3}>{t('users.hidden')}</Label>
                                                        <Col sm={9}>
                                                            <ToggleField name="hidden" />
                                                        </Col>
                                                    </FormGroup>
                                                }
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={12} lg={6}>
                                    <Card>
                                        <CardBody>
                                            <CardTitle>
                                                <i className="fa fa-address-card"></i>&nbsp;
                                                {t('users.address')}
                                            </CardTitle>
                                            <CardText tag="div">
                                                <FormGroup row>
                                                    <Label for="address" sm={3}>{t('users.address')}</Label>
                                                    <Col sm={9}>
                                                        <Field component="input" className="form-control" type="text" name="address" id="address" autoComplete="off" placeholder={t('users.address_placeholder')} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="zip" sm={3}>{t('users.zip')}</Label>
                                                    <Col sm={9}>
                                                        <Field component="input" className="form-control" type="text" name="zip" id="zip" autoComplete="off" placeholder={t('users.zip_placeholder')} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="city" sm={3}>{t('users.city')}</Label>
                                                    <Col sm={9}>
                                                        <Field component="input" className="form-control" type="text" name="city" id="city" autoComplete="off" placeholder={t('users.city_placeholder')} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="country" sm={3}>{t('users.country')}</Label>
                                                    <Col sm={9}>
                                                        <Field component="input" className="form-control" type="text" name="country" id="country" autoComplete="off" placeholder={t('users.country_placeholder')} />
                                                    </Col>
                                                </FormGroup>
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row className="main">
                                <Col sm={12} lg={6}>
                                    <Card>
                                        <CardBody>
                                            <CardTitle>
                                                <i className="fa fa-envelope"></i>&nbsp;
                                                {t('users.contact')}
                                            </CardTitle>
                                            <CardText tag="div">
                                                <FormGroup row>
                                                    <Label for="email" sm={3}>{t('users.email')}</Label>
                                                    <Col sm={9}>
                                                        <EmailField required name="email" placeholder={t('users.email_placeholder')} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="fix_phone" sm={3}>{t('users.fix_phone')}</Label>
                                                    <Col sm={9}>
                                                        <Field component={PhoneField} name="fix_phone" />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="mobile_phone" sm={3}>{t('users.mobile_phone')}</Label>
                                                    <Col sm={9}>
                                                        <Field component={PhoneField} name="mobile_phone" />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="pro_phone" sm={3}>{t('users.pro_phone')}</Label>
                                                    <Col sm={9}>
                                                        <Field component={PhoneField} name="pro_phone" />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="fax" sm={3}>{t('users.fax')}</Label>
                                                    <Col sm={9}>
                                                        <Field component="input" className="form-control" type="text" name="fax" id="fax" autoComplete="off" placeholder={t('users.fax_placeholder')} />
                                                    </Col>
                                                </FormGroup>
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={12} lg={6}>
                                    <Card>
                                        <CardBody>
                                            <CardTitle>
                                                <i className="fa fa-th-list"></i>&nbsp;
                                                {t('users.other_informations')}
                                            </CardTitle>
                                            <CardText tag="div">
                                                <FormGroup row>
                                                    <Label for="birthday" sm={3}>{t('users.birthday')}</Label>
                                                    <Col sm={9}>
                                                        <Field showTime={false} component={renderDateTimePicker} className="form-control" type="text" name="birthday" id="birthday" autoComplete="off" />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="avs" sm={3}>{t('users.avs')}</Label>
                                                    <Col sm={9}>
                                                        <Field component="input" className="form-control" type="text" name="avs" id="avs" placeholder={t('users.avs_placeholder')} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="bank" sm={3}>{t('users.bank')}</Label>
                                                    <Col sm={9}>
                                                        <Field component="input" className="form-control" type="text" name="bank" id="bank" placeholder={t('users.bank_placeholder')} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="bic" sm={3}>{t('users.bic')}</Label>
                                                    <Col sm={9}>
                                                        <Field component="input" className="form-control" type="text" name="bic" id="bic" placeholder={t('users.bic_placeholder')} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="iban" sm={3}>{t('users.iban')}</Label>
                                                    <Col sm={9}>
                                                        <Field component="input" className="form-control" type="text" name="iban" id="iban" placeholder={t('users.iban_placeholder')} />
                                                    </Col>
                                                </FormGroup>
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row className="main">
                                <Col>
                                    <Card>
                                        { isCreation &&
                                        <CardBody>
                                            <CardTitle>Deprecated (Only for superadmin) old roles</CardTitle>
                                            <CardText>
                                                <Field required component="select" className="form-control" type="text" name="role" id="role">
                                                    {isCreation &&
                                                        <option value="" disabled>{t("users.role_placeholder")}</option>}
                                                    {
                                                        ['user', 'admin', 'superAdmin'].map((r) => <option key={r} value={r}>{r}</option>)
                                                    }
                                                </Field>
                                            </CardText>
                                        </CardBody>
                                        }
                                        <CardBody>
                                            <CardTitle><i className="fa fa-ticket"></i>&nbsp;{t("tktInstances.tktInstance")}</CardTitle>
                                            <CardText tag="div">
                                                <FormGroup row>
                                                    <Col>
                                                        {authUser?.abilities?.superadmin &&
                                                            <Field required component="select" className="form-control" type="text" name="tkt_instance_id" id="tkt_instance_id">
                                                                {
                                                                    tktInstances.map((i) => <option key={i.id} value={i.id}>{i.name}</option>)
                                                                }
                                                            </Field>
                                                        }
                                                        {!authUser?.abilities?.superadmin &&
                                                            <>
                                                                {!isCreation && user &&
                                                                    user?.tkt_instance?.name
                                                                }
                                                                {isCreation &&
                                                                    <div>
                                                                        {authUser?.tkt_instance?.name}
                                                                        <Field component="input" type="hidden" name="tkt_instance_id" />
                                                                    </div>
                                                                }
                                                            </>
                                                        }
                                                    </Col>
                                                </FormGroup>
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </TabPane>
            </TabContent>

            <TabContent activeTab={activeTab}>
                <TabPane tabId="2">
                    {authUser.isSuperAdmin() ?
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle>Deprecated (Only for superadmin) old roles</CardTitle>
                                        <CardText>
                                            <Field required component="select" className="form-control" type="text" name="role" id="role">
                                                {isCreation &&
                                                    <option value="" disabled>{t("users.role_placeholder")}</option>}
                                                {
                                                    ['user', 'admin', 'superAdmin'].map((r) => <option key={r} value={r}>{r}</option>)
                                                }
                                            </Field>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        :
                        <Field component="input" type="hidden" name="role" value="user" />
                    }
                    {!isCreation && authUser.can('read_abilities', 'users') &&
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle>
                                            <i className="fa fa-graduation-cap"></i>&nbsp;
                                            Droits
                                        </CardTitle>
                                        <CardText tag="div">
                                            {optionsAbilities.length > 0 &&
                                                <Row>
                                                    <Col sm={12} md={9}>
                                                        {(authUser?.isSuperAdmin()) &&
                                                            <div className="d-flex align-items-center form-group">
                                                                <label className='d-block mr-3 mb-0 h5'>
                                                                    <b>
                                                                        {t("modules.module_super_admin")}
                                                                    </b>
                                                                </label>
                                                                <Field
                                                                    component="input"
                                                                    type="checkbox"
                                                                    className="form-control checkAbility"
                                                                    name={`abilities.superadmin`}
                                                                    format={v => { return !!v }}
                                                                    style={{ height: '25px', width: '25px' }}
                                                                />
                                                            </div>
                                                        }
                                                    </Col>
                                                    {authUser.can('edit_abilities', 'users') &&
                                                        <Col sm={12} md={3}>
                                                            <InputGroup>
                                                                <Input
                                                                    type="select"
                                                                    onChange={e => onSelect(e)}
                                                                    placeholder={t("abilities.chose_abilities_placeholder")}
                                                                >
                                                                    <option key="none" value="">---</option>
                                                                    {optionsAbilities}
                                                                </Input>
                                                                <Button
                                                                    color="success"
                                                                    onClick={() => onApply()}
                                                                >
                                                                    {t("abilities.apply_template")}
                                                                </Button>
                                                            </InputGroup>
                                                        </Col>
                                                    }
                                                </Row>
                                            }
                                            <Row>
                                                <Col>
                                                    {!_.isEmpty(user?.abilities) &&
                                                        <TktInstanceAbilitiesTemplate editable={authUser.can('edit_abilities', 'users')} data={user?.abilities} />
                                                    }
                                                </Col>
                                            </Row>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                </TabPane>
            </TabContent>

            <TabContent activeTab={activeTab}>
                <TabPane tabId="3">
                    <Row className="main">
                        {
                        authUser?.tkt_instance?.hasModule(Utils.MODULE_RESOURCES) &&
                        activities?.length > 0 &&
                        (authUser.can('pick_activities', 'users') || authUser.id === user?.id) &&
                            <Col xs={12} xl={6}>
                                <Card>
                                    <CardBody>
                                        <CardTitle><i className="fa fa-id-badge"></i>&nbsp;{t("users.skills")}</CardTitle>
                                        <CardText tag="div">
                                            <FormGroup row>
                                                <Col>
                                                    <FieldArray name="activities" component={UserActivities} activities={user?.activities} all_activities={activities} />
                                                </Col>
                                            </FormGroup>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        }

                        { authUser?.tkt_instance?.hasModule(Utils.MODULE_TEAM) &&
                          (authUser?.can('pick_activities', 'users') || authUser?.id === user?.id) &&
                            <Col xs={12} xl={6}>
                                <Card>
                                    <CardBody>
                                        <CardTitle><i className="fa fa-calendar-o"></i>&nbsp;{t("users.availabilities")}</CardTitle>
                                        <CardText tag="div">
                                            <FormGroup row>
                                                <Col>
                                                    <AvailabilitiesField name="availabilities" />
                                                </Col>
                                            </FormGroup>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        }
                    </Row>

                    { authUser?.tkt_instance?.hasModule(Utils.MODULE_TEAM) && !isCreation && (
                    <Row className="main">
                        <Col xs={12}>
                            { user?.applications?.map(application => (
                                <Card key={ application.id }>
                                    <CardBody>
                                        <CardTitle><i className="fa fa-id-badge"></i>&nbsp;{t("users.application")}</CardTitle>
                                        <CardText tag="div">
                                            <ApplicationDetails application={ application } />
                                        </CardText>
                                    </CardBody>
                                </Card>
                            ))}
                        </Col>
                    </Row>
                    )}

                    <Row className="main">
                        {
                            authUser?.tkt_instance?.hasModule(Utils.MODULE_TEAM) &&
                            !isCreation && (sectors?.length > 0) &&
                            (authUser?.can('pick_activities', 'users') || authUser?.id === user?.id) &&
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle><i className="fa fa-id-badge"></i>&nbsp;{t("users.sectors")}</CardTitle>
                                        <CardText tag="div">
                                            <FormGroup row>
                                                <Col>
                                                    { isLoading ? (
                                                        <Loader />
                                                    ) : (
                                                        <SectorsField sectors={ sectors } name="sectors" />
                                                    )}
                                                </Col>
                                            </FormGroup>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        }
                    </Row>
                </TabPane>
            </TabContent>

            <Row className="main">
                <Col className="text-center">
                    <ButtonGroup>
                        <Button tag={Link} to="/users" color="warning">
                            <span><i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}</span>
                        </Button>
                        <Button type="submit" color="success" disabled={!templateApplied && (pristine || submitting || isLoading)}>
                            <span>
                                <i className={`fa ${isLoading ? 'fa-spinner fa-spin' : 'fa fa-save'}`}></i>&nbsp;{t("common.save")}
                            </span>
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
        </Form>
    );
}

const mapDispatchToProps = {};

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
UserForm = reduxForm({
    form: 'userForm', // a unique identifier for this form
    enableReinitialize: true,
    destroyOnUnmount: false
})(UserForm)

// You have to connect() to any reducers that you wish to connect to yourself
UserForm = connect(
    state => ({
        initialValues: state.users.user,
        users: state.users,
        tktInstances: state.tktInstances,
        activities: state.activities,
    }), mapDispatchToProps
)(UserForm)

export default UserForm;
