import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'redux-form'
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardBody, CardTitle, FormGroup, Label, Button, Tooltip, Badge } from 'reactstrap'
import { DateTimePicker, TranslatableField, WysiwygField } from '../../../../components';
import { Utils } from '../../../../services';
import moment from 'moment';
import { loadPlaces } from '../../../../redux';

const ScreeningFormInfo = ({ isLoading, isCreation }) => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    const { screening }                   = useSelector(state => state.screenings);
    const { places }                      = useSelector(state => state.places);
    const [ tooltipOpen, setTooltipOpen ] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    useEffect(() => {
        dispatch(loadPlaces());
    }, []);

    const yesOrNoOptions = [
        <option key="yes" value={true}>{t('common.yes')}</option>,
        <option key="no" value={false}>{t('common.no')}</option>
    ]

    const oneOrZeroOptions = [
        <option key={1} value={true}>{t('common.yes')}</option>,
        <option key={0} value={false}>{t('common.no')}</option>
    ]

    const supportOptions = [
        <option key="nosupp" value="">---</option>,
        Utils.programmation_supports().map((s, i) => {
            return (
                <option key={s} value={s._id}>{t("programmations.support_" + s)}</option>
            )
        })
    ] || [];

    const sectionsOption  = isCreation ? [] : [
        screening.sections.map((section, i) => {
            return (
                <span key={i}>{Utils.localized_or_fallback(section.name)}{i !== screening.sections.length - 1 ? ', ' : ''}</span>
            );
        })
    ];

    return (
        <>
            <Row>
                <Col lg={9} style={{ height: "fit-content" }}>
                    <Card>
                        <CardBody>
                            <CardTitle>
                                <div className="d-sm-flex justify-content-sm-between">
                                    <span>
                                        <i className="fa fa-info-circle"></i>&nbsp; {t('posts.informations')}
                                    </span>
                                    <span>
                                        <Badge title={t('screenings._id')} color="info" className="text-small ml-2" pill>{screening._id}</Badge>
                                    </span>
                                </div>
                                <div className="text-sm-right">
                                    {screening?.refs?.length > 0 && screening.refs.map((ref, i) => {
                                        if (ref.display() !== screening._id)
                                            return (
                                                <Badge title={t('screenings.refs')}  color="secondary" className="right-20 text-small ml-2" pill>{ref.display()}</Badge>
                                            )
                                    })}
                                </div>
                            </CardTitle>
                            <CardBody>
                                <div className="mb-3">
                                    <FormGroup row>
                                        <Label for="title" sm={12} xs={12} md={3}>{t('screenings.edit.form.general.names')}</Label>
                                        <Col sm={12} xs={12} md={9}>
                                            <TranslatableField original className="form-control" type="text" data-for="title" component="input" name="title" id="title" required placeholder={t('screenings.edit.form.name_placeholder')} />
                                        </Col>
                                    </FormGroup>
                                </div>
                                <div className="mb-3">
                                    <FormGroup row>
                                        <Label for="start_at" sm={12} xs={12} md={3}>{t('screenings.edit.form.general.start_at')} - {t('screenings.edit.form.general.stop_at')}</Label>
                                        <Col sm={12} xs={12} md={9} id="date-wrapper">
                                            <Row>
                                                <Col xs={12} sm={6}>
                                                    <Field
                                                        component={DateTimePicker}
                                                        name="start_at"
                                                        disabled={!isCreation && (!screening || moment(screening?.start_at).isBefore(moment()))}
                                                    />
                                                </Col>
                                                <Col xs={12} sm={6}>
                                                    <Field
                                                        component={DateTimePicker}
                                                        name="stop_at"
                                                        disabled={!isCreation && (!screening || moment(screening?.start_at).isBefore(moment()))}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        {(!screening || moment(screening?.start_at).isBefore(moment())) &&
                                            <Tooltip
                                                placement={ 'top' }
                                                isOpen={tooltipOpen }
                                                target={ `date-wrapper` }
                                                toggle={ toggle }
                                            >
                                               {t('screenings.date_disabled_tooltip')}
                                            </Tooltip>
                                        }
                                    </FormGroup>
                                </div>
                                <div className="mb-3">
                                    <FormGroup row>
                                        <Label for="description" sm={12} xs={12} md={3}>{t('screenings.edit.form.general.descriptions')}</Label>
                                        <Col sm={12} xs={12} md={9}>
                                            <TranslatableField original className="form-control" type="text" data-for="description" component={WysiwygField} name="description" id="description" placeholder={t('screenings.edit.form.description_placeholder')} required={isCreation} />
                                        </Col>
                                    </FormGroup>
                                </div>
                                <div>
                                    <FormGroup row>
                                        <Label for="note" sm={12} xs={12} md={3}>{t('screenings.edit.form.general.note')}</Label>
                                        <Col sm={12} xs={12} md={9}>
                                            <Field component="input" className="form-control" type="text" name="note" id="note" placeholder={t('screenings.edit.form.note_placeholder')} />
                                        </Col>
                                    </FormGroup>
                                </div>
                            </CardBody>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={3}>
                    {/* Choosing a room, only when creating  */}
                    { isCreation &&
                    <Row>
                        <Col>
                            <Card className="w-100">
                                <CardBody>
                                    <CardTitle>
                                        <i className="fa fa-th"></i>&nbsp;
                                        {t('places.place')}
                                    </CardTitle>
                                    <CardBody>
                                        <FormGroup>
                                            <Field component="select" className="form-control" type="text" name="cinema_hall" required >
                                                <option value="" > --- </option>
                                                {places && places.map(p =>
                                                    <option key={p._id} value={p._id} >{p.name}</option>
                                                )}
                                            </Field>
                                        </FormGroup>
                                    </CardBody>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    }
                    <Row>
                        <Col>
                            <Card className="w-100">
                                <CardBody>
                                    <CardTitle>
                                        <i className="fa fa-film"></i>&nbsp;
                                        {t('screenings.details')}
                                    </CardTitle>
                                    {sectionsOption.length > 0 &&
                                        <FormGroup>
                                            <Label>{t('screenings.sections')}</Label>
                                            <p> { screening.sections?.length > 0 ? sectionsOption : t('screenings.no_section') } </p>
                                        </FormGroup>
                                    }
                                    <FormGroup>
                                        <Label>{t('screenings.map_only_bookings')}</Label>
                                        <Field component="select" className="form-control" type="text" name="opaque.map_only_bookings">
                                            {oneOrZeroOptions}
                                        </Field>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>{t('screenings._3d')}</Label>
                                        <Field component="select" className="form-control" type="text" name="opaque._3d">
                                            {yesOrNoOptions}
                                        </Field>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>{t('screenings.break')}</Label>
                                        <Field component="select" className="form-control" type="text" name="opaque.break">
                                            {yesOrNoOptions}
                                        </Field>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>{t('screenings.version')}</Label>
                                        <Field component="input" className="form-control" type="text" name="opaque.version" placeholder={t('screenings.edit.form.version_placeholder')} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>{t('screenings.format')}</Label>
                                        <Field component="input" className="form-control" type="text" name="opaque.format" placeholder={t('screenings.edit.form.format_placeholder')} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>{t('screenings.support')}</Label>
                                        <Field component="select" className="form-control" type="text" name="opaque.support">
                                            {supportOptions}
                                        </Field>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>{t('screenings.ignore_on_maccsbox')}</Label>
                                        <Field component="select" className="form-control" type="text" name="opaque.ignore_on_maccsbox">
                                            {yesOrNoOptions}
                                        </Field>
                                    </FormGroup>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col className="text-center">
                    <Button type="submit" color="success" disabled={isLoading}>
                        <span>
                            <i className={`fa fa-${isLoading ? 'spinner fa-spin' : 'save'}`}></i>&nbsp;
                            {t("common.save")}
                        </span>
                    </Button>
                </Col>
            </Row>
        </>
    )
}

export default ScreeningFormInfo
